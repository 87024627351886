/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DetailOrderTransaction } from 'types';
import {
  AuthorizedForm,
  ICustomerInfo,
  ICustomerInfoInput,
  IGetPricebook,
  IGetSlashPrice,
  IPayments,
  ISalesman,
  IShippingInfo,
  PriceBook,
  SlashPrice,
} from 'types/common.types';
import { IItemCart, IProductList, SplitItemChild, Variant } from 'types/products.types';
import {
  ActionChooseFreeItem,
  IFreeItemsV2,
  IGetPromotionData,
  IPromotions,
  IValidVoucherList,
  PromotionActionFreeItem,
  ResPromotionTransactionAmount,
  SetPromotionTransaction,
} from 'types/promotion.types';
import {
  ICartList,
  IGerateQRISDynamicResponse,
  IOrderData,
  ISalesState,
  ITotalDetail,
} from 'types/sales.types';
import {
  dateUTCBuyItems,
  formatItemVariantsName,
  getAmountItem,
  getDiscountItem,
  getItemFinalDiscount,
  getItemTax,
  getTotalPerItem,
  mappingBatchNumberCart,
  mappingCustomerData,
  mappingItemsCartFromOrder,
  mappingItemsOrder,
  mappingOrderDataContinuePayment,
  mappingPaymentsOrder,
  mappingSerialNumberCart,
  slashPriceProperties,
} from 'utils';

const initialState: ISalesState = {
  listProducts: [],
  listCustomers: [],
  listItemCart: [],
  customerInfo: null,
  salesmenInfo: null,
  detailOrderHistory: null,
  order: {
    salesorder_id: 0,
    salesorder_no: '',
    account_id: 0,
    contact_id: 0,
    salesmen_id: 0,
    salesmen_name: '',
    user_name: '',
    transaction_date: '',
    location_id: 0,
    register_id: 0,
    items: [],
    payments: [],
    sub_total: 0,
    total_disc: 0,
    total_tax: 0,
    grand_total: 0,
    promotions: [],
    discounts: [],
    note: '',
    customer: {
      b_address: '',
      b_area: '',
      b_city: '',
      b_post_code: '',
      b_province: '',
      contact_id: -1,
      contact_name: 'Pelanggan Umum',
      contact_type: 0,
      email: '',
      location_id: 0,
      phone: '',
      is_update_email: false,
      category_id: -1,
    },
    closure_id: 0,
    version: '',
    add_fee: 0,
    add_disc: 0,
    service_fee: 0,
    is_tax_included: false,
    pos_outlet_discount: 0,
    pos_promotion_discount: 0,
    pos_cashier_input_discount: 0,
    is_canceled: false,
    location_tax: 0,
    location_discount: 0,
    customer_name: 'Pelanggan Umum',
    shipping: null,
    pos_is_shipping: false,
    is_send_email: false,
  },
  discount_trx_amount: 0,
  discount_trx_percent: 0,
  isDiscountPercentTrx: true,
  other_cost_percent: 0,
  isOtherCostPercent: true,
  other_cost: 0,
  notes_trx: '',
  totalSavedCart: 0,
  listPayments: [],
  duePayment: 0,
  pos_is_shipping: false,
  shippingInfo: null,
  sendStructByEmail: false,
  listDiscounts: [],
  continuePayment: false,
  remainingPayment: 0,
  prevPayments: [],
  listTransactionPromotion: [],
  listPromotionsItems: [],
  listSelectedPromotion: [],
  listPromotionAmount: [],
  listPromotionItem: [],
  listFreeItemV2: [],
  listValidVoucher: [],
  listAllPromotion: [],
  listDiscountItem: [],
  listPricebookItem: [],
  processPromotions: {},
  currentListPromotion: [],
  totalDetail: null,
  cartId: null,
  isOpenPopup: false,
  qrisDynamic: null,
  loadingSendOrder: false,
  isLoadingCheckout: false,
  isCheckout: false,
  isContact: false,
  errorPromotion: '',
};

function updateTransactionPromotions(processPromotion: any, listTransactionPromotions: any) {
  const newListPromotions = [...listTransactionPromotions];
  for (const idx in newListPromotions) {
    const p = newListPromotions[idx];
    if (p.rules.promotion_id === processPromotion.promotion_id) {
      newListPromotions[idx] = Object.assign({}, p, {
        free_items: processPromotion.free_items,
        free_products: processPromotion.free_products,
        free_categories: processPromotion.free_categories,
      });

      return newListPromotions;
    }
  }

  return null;
}

function updatePayment(processPayment: any, listPayment: any) {
  const newListPayment = [...listPayment];
  for (const idx in newListPayment) {
    const p = newListPayment[idx];
    if (p.payment_id === processPayment.payment_id) {
      if (processPayment.payment_id === -1) {
        newListPayment[idx] = Object.assign({}, p, {
          payment_amount: processPayment.payment_amount,
          dp_amount: processPayment.dp_amount,
          is_dp: processPayment.is_dp,
          payment_charge: processPayment.is_dp
            ? processPayment.payment_amount - processPayment.dp_amount
            : processPayment.payment_charge,
        });
      } else {
        newListPayment[idx] = Object.assign({}, p, {
          payment_amount: newListPayment[idx].payment_amount + processPayment.payment_amount,
          dp_amount:
            Number(newListPayment[idx].dp_amount ?? 0) +
            (processPayment.is_dp === true && newListPayment[idx].is_dp !== processPayment.is_dp
              ? processPayment.dp_amount + newListPayment[idx].payment_amount
              : Number(processPayment.payment_amount) - Number(processPayment.payment_charge)),
          is_dp: processPayment.is_dp === true ? processPayment.is_dp : newListPayment[idx].is_dp,
          payment_charge: Number(newListPayment[idx].payment_charge ?? 0) + processPayment.payment_charge,
        });
      }

      return newListPayment;
    }
  }

  return [];
}

function getDuePayment(newPayments: any, grand_total: number) {
  let duePayment = 0;
  const findVoucher = newPayments.filter((item: any) => item.payment_id === -5);
  const findOtherPayment = newPayments.filter((item: any) => item.payment_id !== -5);
  const totalPaymentAmount = newPayments.reduce(
    (acc: any, cur: any) => acc + (cur.is_dp ? Number(cur.dp_amount) : Number(cur.payment_amount)),
    0
  );

  if (findVoucher && findVoucher.length > 0 && findOtherPayment && findOtherPayment.length === 0) {
    if (totalPaymentAmount < grand_total) {
      duePayment = totalPaymentAmount - grand_total;
    } else {
      duePayment = 0;
    }
  } else {
    duePayment = totalPaymentAmount - grand_total;
  }

  return duePayment;
}

export const salesSlice = createSlice({
  name: 'sales-slice',
  initialState,
  reducers: {
    removeOrderNo: (state) => {
      state.order.salesorder_no = '';
    },
    saveProducts: (state, action: PayloadAction<Array<IProductList>>) => {
      state.listProducts = action.payload;
    },
    loadItems: (state, action) => {
      state.listProducts = action.payload;
    },
    saveCustomers: (state, action: PayloadAction<Array<ICustomerInfo>>) => {
      state.listCustomers = action.payload;
    },
    loadCustomers: (state, action) => {
      state.listCustomers = action.payload;
    },
    selectCustomer: (state, action: PayloadAction<ICustomerInfo>) => {
      state.customerInfo = action.payload;
    },
    setItemCart: (state, action: PayloadAction<IItemCart[]>) => {
      return {
        ...state,
        listItemCart: action.payload,
      };
    },
    removeCustomer: (state) => {
      state.customerInfo = null;
      state.order.customer = initialState.order.customer;
      state.shippingInfo = initialState.shippingInfo;
    },
    removeSaleman: (state) => {
      state.salesmenInfo = null;
      state.order.salesmen_id = 0;
      state.order.salesmen_name = '';
    },
    // select salemen
    selectSaleman: (state, action: PayloadAction<ISalesman>) => {
      if (state.isLoadingCheckout) return;
      state.salesmenInfo = action.payload;
      state.order.salesmen_id = action.payload.salesmen_id;
      state.order.salesmen_name = action.payload.salesmen_name;
    },
    chooseItem: (state, action: PayloadAction<Variant & IItemCart>) => {
      if (state.isLoadingCheckout) return;
      const existItem = state.listItemCart.find((item) => item.item_id === action.payload.item_id);
      const { payload } = action;
      if (!existItem) {
        state.listItemCart.push({
          ...payload,
          item_name: payload.is_from_cart ? payload.item_name : formatItemVariantsName(action.payload),
          tax_percent: payload.tax_percent,
          isDiscountPercent: true,
          serial_number: payload.serial_number,
          batch_number: payload.batch_number,
          normal_price: payload.normal_price ?? payload.sell_price,
          default_price: Number(payload.normal_price) || Number(payload.sell_price),
          is_edit_price: payload.is_edit_price ?? false,
          is_master: true,
          child_id: 0,
          child: [],
        });
      } else {
        state.listItemCart = state.listItemCart.map((item) => {
          if (item.item_id === payload.item_id && item.is_master) {
            const newSerialNo = payload.serial_number && payload.serial_number[0].serial_no;
            const existSerialNumber = item.serial_number?.find((itemSn) => itemSn.serial_no === newSerialNo);
            if (!action.payload.serial_number || !existSerialNumber) item.quantity += 1;
            item.serial_number = payload.serial_number
              ? mappingSerialNumberCart(item.serial_number ?? [], payload.serial_number[0])
              : item.serial_number;
            item.batch_number = payload.batch_number
              ? mappingBatchNumberCart(item.batch_number ?? [], payload.batch_number)
              : item.batch_number;
          }
          return item;
        });
      }
    },
    chooseItemSaveTransaction: (state, action: PayloadAction<Variant & IItemCart>) => {
      if (state.isLoadingCheckout) return;
      const { payload } = action;
      state.listItemCart.push({
        ...payload,
      });
    },
    splitItem: (state, action: PayloadAction<Variant & IItemCart>) => {
      if (state.isLoadingCheckout) return;
      const { payload } = action;
      let tempChildId: SplitItemChild[] = [];
      let lastChild = 0;
      let sumBatch = 0;
      const masterItem = state.listItemCart.find(
        (item) => item.item_id === payload.item_id && item.is_master
      );
      if (masterItem && masterItem.quantity - 1 < 1) return;
      state.listItemCart = state.listItemCart.map((item) => {
        if (item.item_id === payload.item_id && item.is_master) {
          if (item?.child && item?.child.length > 0) {
            const lasChildId = item.child[item.child.length - 1].child_id ?? 1;
            item.child.push({ child_id: lasChildId + 1 });
            tempChildId = item.child;
            lastChild = lasChildId + 1;
          } else {
            item.child = [{ child_id: 1 }];
            tempChildId = item.child;
            lastChild = 1;
          }
          const updateQty = item.quantity - 1;
          const slash_price = `slash_price_item_${item.item_id}_0`;
          let sellPrice = Number(item.sell_price ?? 0);
          if (item.serial_number && item.serial_number.length > 0) {
            const currentSelectedSerialNumber = [...item.serial_number];

            const newSelectedSerialNumber =
              updateQty > item.quantity
                ? currentSelectedSerialNumber
                : currentSelectedSerialNumber.splice(0, updateQty);
            item.serial_number = newSelectedSerialNumber;
          }
          if (item.batch_number && item.batch_number.length > 0) {
            const currentSelectedBatch = [...item.batch_number];
            const lastIndex = currentSelectedBatch.length - 1;
            const lastBatch = currentSelectedBatch[lastIndex];
            const qtyBatch = currentSelectedBatch[lastIndex].qty ?? 0;
            const lastQty = qtyBatch - 1;
            sumBatch = item.batch_number
              .map((a) => Number(a.qty))
              .reduce(function (a, b) {
                return a + b;
              });
            if (
              (qtyBatch <= 1 || lastQty < 1) &&
              item.batch_number.length > 1 &&
              sumBatch === item.quantity
            ) {
              currentSelectedBatch.pop();
            } else if (lastQty > 0 && item.quantity === sumBatch) {
              currentSelectedBatch[lastIndex] = {
                ...lastBatch,
                qty: qtyBatch === 0 ? 1 : lastQty,
              };
            }
            item.batch_number = currentSelectedBatch;
          }
          item.quantity = updateQty;
          if (item.list_price_book && item.list_price_book.length > 0) {
            const sortPriceBookDesc = item.list_price_book.sort((a, b) => (a.min_unit > b.min_unit ? -1 : 1));
            const findPriceBook = sortPriceBookDesc.find((e) => Number(e.min_unit) <= updateQty);
            sellPrice =
              findPriceBook && !item.is_edit_price ? findPriceBook.retail_price : Number(item.normal_price);
            item.sell_price = sellPrice;
            item.price_book = findPriceBook
              ? {
                  price_book_id: findPriceBook.price_book_id,
                  retail_price: findPriceBook.retail_price,
                  item_id: findPriceBook.item_id,
                  min_unit: findPriceBook.min_unit,
                }
              : null;
            item.list_price_book = item.list_price_book.sort((a, b) => (a.min_unit > b.min_unit ? 1 : -1));
          }
          item.pos_slash_price = item.slash_price
            ? sellPrice < Number(item.slash_price[slash_price].promotion_price)
              ? 0
              : updateQty * (sellPrice - Number(item.slash_price[slash_price].promotion_price))
            : 0;
        } else if (item.item_id === payload.item_id) {
          item.child = tempChildId;
        }
        return item;
      });

      const qty = 1;
      const slash_price = `slash_price_item_${payload.item_id}_0`;
      let sellPrice = Number(payload.default_price) || Number(payload.normal_price);
      let priceBook = null;
      let sortPriceBookDesc: PriceBook[] = [];
      if (payload.list_price_book && payload.list_price_book.length > 0) {
        sortPriceBookDesc = [...payload.list_price_book].sort((a, b) => (a.min_unit > b.min_unit ? -1 : 1));
        const findPriceBook = sortPriceBookDesc.find((e) => Number(e.min_unit) <= qty);
        sellPrice =
          findPriceBook && !payload.is_edit_price
            ? findPriceBook.retail_price
            : Number(payload.default_price);
        priceBook = findPriceBook
          ? {
              price_book_id: findPriceBook.price_book_id,
              retail_price: findPriceBook.retail_price,
              item_id: findPriceBook.item_id,
              min_unit: findPriceBook.min_unit,
            }
          : null;
      }
      let serialNo = payload.serial_number;
      if (serialNo && serialNo.length > 1) {
        serialNo = [serialNo[serialNo.length - 1]];
      } else {
        serialNo = null;
      }
      let batchNo = payload.batch_number;
      if (batchNo && batchNo.length > 0) {
        if (sumBatch != payload.quantity) {
          batchNo = null;
        } else {
          const batchNumbers = { ...batchNo[batchNo.length - 1] };
          batchNumbers.qty = 1;
          batchNo = [batchNumbers];
        }
      }
      let updateSlashPrice = null;
      if (payload.slash_price) {
        updateSlashPrice = slashPriceProperties(payload.slash_price, payload.item_id, lastChild);
      }
      state.listItemCart.push({
        ...payload,
        quantity: qty,
        item_name: payload.is_from_cart ? payload.item_name : formatItemVariantsName(action.payload),
        tax_percent: 0,
        tax_amount: 0,
        sell_price: sellPrice,
        disc: 0,
        discount_amount: 0,
        discount_percent: 0,
        price_book: priceBook,
        list_price_book: sortPriceBookDesc,
        slash_price: updateSlashPrice,
        pos_slash_price: payload.slash_price
          ? sellPrice < Number(payload.slash_price[slash_price].promotion_price)
            ? 0
            : qty * (sellPrice - Number(payload.slash_price[slash_price].promotion_price))
          : 0,
        isDiscountPercent: true,
        serial_number: serialNo,
        batch_number: batchNo,
        normal_price: sellPrice,
        notes: '',
        is_edit_price: false,
        is_master: false,
        child: tempChildId,
        child_id: lastChild,
      });
    },
    decrementItem: (state, action: PayloadAction<SplitItemChild>) => {
      if (state.isLoadingCheckout) return;
      let findRemoveMaster: IItemCart = {} as IItemCart;
      state.listItemCart = state.listItemCart.map((item) => {
        if (item.item_id === action.payload.item_id && item.child_id === action.payload.child_id) {
          const updateQty = item.quantity - 1;
          const slash_price = `slash_price_item_${item.item_id}_${item.child_id}`;
          let sellPrice = Number(item.sell_price ?? 0);
          if (item.serial_number && item.serial_number.length > 0) {
            const currentSelectedSerialNumber = [...item.serial_number];

            const newSelectedSerialNumber =
              updateQty > item.quantity
                ? currentSelectedSerialNumber
                : currentSelectedSerialNumber.splice(0, updateQty);
            item.serial_number = newSelectedSerialNumber;
          }
          item.quantity = updateQty;
          if (item.list_price_book && item.list_price_book.length > 0) {
            const sortPriceBookDesc = item.list_price_book.sort((a, b) => (a.min_unit > b.min_unit ? -1 : 1));
            const findPriceBook = sortPriceBookDesc.find((e) => Number(e.min_unit) <= updateQty);
            sellPrice =
              findPriceBook && !item.is_edit_price ? findPriceBook.retail_price : Number(item.normal_price);
            item.sell_price = sellPrice;
            item.price_book = findPriceBook
              ? {
                  price_book_id: findPriceBook.price_book_id,
                  retail_price: findPriceBook.retail_price,
                  item_id: findPriceBook.item_id,
                  min_unit: findPriceBook.min_unit,
                }
              : null;
            item.list_price_book = item.list_price_book.sort((a, b) => (a.min_unit > b.min_unit ? 1 : -1));
          }
          item.pos_slash_price = item.slash_price
            ? sellPrice < Number(item.slash_price[slash_price].promotion_price)
              ? 0
              : updateQty * (sellPrice - Number(item.slash_price[slash_price].promotion_price))
            : 0;

          if (item.is_master && updateQty === 0 && item.child && item.child?.length > 0) {
            findRemoveMaster = item;
          }
        }
        return item;
      });

      if (Object.keys(findRemoveMaster).length > 0) {
        let childMaster: SplitItemChild[];
        state.listItemCart = state.listItemCart.map((item) => {
          if (
            item.item_id === findRemoveMaster.item_id &&
            findRemoveMaster.child &&
            findRemoveMaster.child.length > 0 &&
            findRemoveMaster.child[0].child_id === item.child_id
          ) {
            if (item.slash_price) {
              const updateSlashPrice = slashPriceProperties(
                item.slash_price,
                item.item_id,
                item.child_id ?? 0,
                true
              );
              item.slash_price = updateSlashPrice;
            }
            childMaster = item.child?.slice(1) ?? [];
            item.is_master = true;
            item.child_id = 0;
            item.child = childMaster;
          } else if (item.item_id === findRemoveMaster.item_id && !item.is_master) {
            item.child = childMaster;
          }
          return item;
        });
      }
      state.listItemCart = state.listItemCart.filter((item) => item.quantity !== 0);
    },
    incrementItem: (state, action: PayloadAction<SplitItemChild>) => {
      if (state.isLoadingCheckout) return;
      state.listItemCart = state.listItemCart.map((item) => {
        if (item.item_id === action.payload.item_id && item.child_id === action.payload.child_id) {
          const updateQty = item.quantity + 1;
          const slash_price = `slash_price_item_${item.item_id}_${item.child_id}`;
          let sellPrice = Number(item.sell_price ?? 0);
          item.quantity = updateQty;
          if (item.list_price_book && item.list_price_book.length > 0) {
            const sortPriceBookDesc = item.list_price_book.sort((a, b) => (a.min_unit > b.min_unit ? -1 : 1));
            const findPriceBook = sortPriceBookDesc.find((e) => Number(e.min_unit) <= updateQty);
            sellPrice =
              findPriceBook && !item.is_edit_price ? findPriceBook.retail_price : Number(item.normal_price);
            item.sell_price = sellPrice;
            item.price_book = findPriceBook
              ? {
                  price_book_id: findPriceBook.price_book_id,
                  retail_price: findPriceBook.retail_price,
                  item_id: findPriceBook.item_id,
                  min_unit: findPriceBook.min_unit,
                }
              : null;
            item.list_price_book = item.list_price_book.sort((a, b) => (a.min_unit > b.min_unit ? 1 : -1));
          }
          item.pos_slash_price = item.slash_price
            ? sellPrice < Number(item.slash_price[slash_price].promotion_price)
              ? 0
              : updateQty * (sellPrice - Number(item.slash_price[slash_price].promotion_price))
            : 0;
        }
        return item;
      });
    },
    resetOrder: (_state) => {
      if (_state.isLoadingCheckout) return;
      return {
        ...initialState,
        listProducts: _state.listProducts,
      };
    },
    removeItem: (state, action: PayloadAction<number>) => {
      if (state.isLoadingCheckout) return;
      state.listItemCart = state.listItemCart.filter((item) => item.item_id !== action.payload);
    },
    removeItemSplit: (state, action: PayloadAction<SplitItemChild>) => {
      if (state.isLoadingCheckout) return;
      const listItem = state.listItemCart.filter(
        (item) => !(item.item_id === action.payload.item_id && item.child_id === action.payload.child_id)
      );
      if (listItem.length > 0 && action.payload.child_id === 0) {
        const firstChildItem = listItem.filter((item) => item.item_id === action.payload.item_id);
        let child: SplitItemChild[] = [];
        state.listItemCart = listItem.map((item) => {
          if (
            firstChildItem.length > 0 &&
            item.child_id === firstChildItem[0].child_id &&
            item.item_id === action.payload.item_id
          ) {
            if (item.slash_price) {
              const updateSlashPrice = slashPriceProperties(
                item.slash_price,
                item.item_id,
                item.child_id ?? 0,
                true
              );
              item.slash_price = updateSlashPrice;
            }
            item.is_master = true;
            item.child_id = 0;
            child = item.child?.slice(1) ?? [];
          }
          item.child = child;
          return item;
        });
      } else {
        state.listItemCart = listItem;
      }
    },
    createOrderAction: (state, action: PayloadAction<IOrderData>) => {
      return {
        ...state,
        order: {
          ...state.order,
          salesorder_id: 0,
          salesorder_no: action.payload.salesorder_no,
          account_id: 1,
          contact_id: action.payload.contact_id ?? -1,
          salesmen_id: action.payload.salesmen_id,
          salesmen_name: action.payload.salesmen_name,
          transaction_date: dateUTCBuyItems(),
          register_id: action.payload.register_id,
          location_id: action.payload.location_id,
          user_name: action.payload.user_name,
          closure_id: action.payload.closure_id,
        },
      };
    },
    updateTotalSavedCart: (state, action: PayloadAction<number>) => {
      state.totalSavedCart = action.payload;
    },
    selectSavedCart: (state, action: PayloadAction<ICartList>) => {
      state.customerInfo = action.payload.customer as ICustomerInfo;
      state.salesmenInfo = action.payload.salesmenInfo as ISalesman;
      state.shippingInfo = action.payload.shipping;
      state.pos_is_shipping = action.payload.shipping ? true : false;
      state.discount_trx_amount = action.payload.discount_trx_amount;
      state.discount_trx_percent = action.payload.discount_trx_percent;
      state.isDiscountPercentTrx = action.payload.isDiscountPercentTrx;
      state.isOtherCostPercent = action.payload.isOtherCostPercent;
      state.notes_trx = action.payload.notes;
      state.other_cost_percent = action.payload.other_cost_percent;
      state.other_cost = action.payload.otherCostAmount;
      state.cartId = Number(action.payload.id);
    },
    updateItemDetail: (state, action: PayloadAction<IItemCart>) => {
      if (state.isLoadingCheckout) return;
      const data = action.payload;
      state.listItemCart = state.listItemCart.map((item) => {
        if (item.item_id === data.item_id && item.child_id === data.child_id) {
          const slash_price = `slash_price_item_${item.item_id}_${item.child_id}`;
          let sellPrice = data.is_edit_price ? Number(data.sell_price) : Number(item.normal_price);
          if (!data.is_edit_price && item.list_price_book) {
            const sortPriceBookDesc = item.list_price_book.sort((a, b) => (a.min_unit > b.min_unit ? -1 : 1));
            const findPriceBook = sortPriceBookDesc.find((e) => Number(e.min_unit) <= Number(data.quantity));
            if (findPriceBook) sellPrice = findPriceBook.retail_price;
            item.price_book = findPriceBook
              ? {
                  price_book_id: findPriceBook.price_book_id,
                  retail_price: findPriceBook.retail_price,
                  item_id: findPriceBook.item_id,
                  min_unit: findPriceBook.min_unit,
                }
              : null;
            item.list_price_book = item.list_price_book.sort((a, b) => (a.min_unit > b.min_unit ? 1 : -1));
          }
          item.sell_price = sellPrice;
          item.pos_slash_price = item.slash_price
            ? sellPrice < Number(item.slash_price[slash_price].promotion_price)
              ? 0
              : Number(data.quantity) * (sellPrice - Number(item.slash_price[slash_price].promotion_price))
            : 0;
          item.discount_amount = !data.isDiscountPercent ? Number(data.discount_amount) : 0;
          item.discount_percent = data.isDiscountPercent ? Number(data.discount_percent) : 0;
          item.isDiscountPercent = data.isDiscountPercent;
          item.tax_percent = Number(data.tax_percent);
          item.tax_id = Number(data.tax_id);
          item.quantity = Number(data.quantity);
          item.notes = data.notes ?? '';
          item.serial_number = data.serial_number;
          item.batch_number = data.batch_number;
          item.amount = getTotalPerItem(data, state.listPromotionsItems);
          item.is_edit_price = data.is_edit_price;
          item.normal_price = data.is_edit_price ? data.sell_price : item.normal_price;
        }
        return item;
      });
    },
    updateDetailOrder: (state, action: PayloadAction<ISalesState>) => {
      if (state.isLoadingCheckout) return;
      state.discount_trx_amount = Number(action.payload.discount_trx_amount);
      state.discount_trx_percent = Number(action.payload.discount_trx_percent);
      state.isDiscountPercentTrx = action.payload.isDiscountPercentTrx;
      state.other_cost_percent = action.payload.other_cost_percent;
      state.other_cost = Number(action.payload.other_cost);
      state.isOtherCostPercent = action.payload.isOtherCostPercent;
      state.notes_trx = action.payload.notes_trx;
      state.pos_is_shipping = action.payload.pos_is_shipping;
      state.shippingInfo = action.payload.pos_is_shipping ? action.payload.shippingInfo : null;
    },
    updateShippingOrder: (state, action: PayloadAction<IShippingInfo>) => {
      state.shippingInfo = action.payload ?? null;
    },
    updateQuantityItem: (state, action: PayloadAction<SplitItemChild>) => {
      if (state.isLoadingCheckout) return;
      state.listItemCart = state.listItemCart.map((item) => {
        if (item.item_id === action.payload.item_id && item.child_id === action.payload.child_id) {
          const updateQty = Number(action.payload.quantity);
          const slash_price = `slash_price_item_${item.item_id}_${item.child_id}`;
          let sellPrice = Number(item.sell_price ?? 0);
          if (item.serial_number && item.serial_number.length > 0) {
            const currentSelectedSerialNumber = [...item.serial_number];

            const newSelectedSerialNumber =
              updateQty > item.quantity
                ? currentSelectedSerialNumber
                : currentSelectedSerialNumber.splice(0, updateQty);
            item.serial_number = newSelectedSerialNumber;
          }
          item.quantity = updateQty;
          if (item.list_price_book && item.list_price_book.length > 0) {
            const sortPriceBookDesc = item.list_price_book.sort((a, b) => (a.min_unit > b.min_unit ? -1 : 1));
            const findPriceBook = sortPriceBookDesc.find((e) => e.min_unit <= updateQty);
            sellPrice =
              findPriceBook && !item.is_edit_price ? findPriceBook.retail_price : Number(item.normal_price);
            item.sell_price = sellPrice;
            item.price_book = findPriceBook
              ? {
                  price_book_id: findPriceBook.price_book_id,
                  retail_price: findPriceBook.retail_price,
                  item_id: findPriceBook.item_id,
                  min_unit: findPriceBook.min_unit,
                }
              : null;
            item.list_price_book = item.list_price_book.sort((a, b) => (a.min_unit > b.min_unit ? 1 : -1));
          }
          item.pos_slash_price = item.slash_price
            ? sellPrice < Number(item.slash_price[slash_price].promotion_price)
              ? 0
              : updateQty * (sellPrice - Number(item.slash_price[slash_price].promotion_price))
            : 0;
        }
        return item;
      });
    },
    checkoutAction: (
      state: ISalesState,
      action: PayloadAction<{ orders: IOrderData; listItemCart: IItemCart[] }>
    ) => {
      const duePayment: number = state.listPayments.reduce(
        (acc, cur) => acc + (cur.is_dp ? Number(cur.dp_amount) : Number(cur.payment_amount)),
        0
      );
      const { orders, listItemCart } = action.payload;
      return {
        ...state,
        order: {
          ...state.order,
          customer: orders.customer,
          other_cost: orders.other_cost ?? 0,
          service_fee: orders.service_fee ?? 0,
          grand_total: orders.grand_total ?? 0,
          total_disc: orders.total_disc ?? 0,
          total_tax: orders.total_tax ?? 0,
          sub_total: orders.sub_total ?? 0,
          add_fee: orders.add_fee ?? 0,
          add_disc: orders.add_disc ?? 0,
          note: orders.note ?? '',
          items: mappingItemsOrder(listItemCart, state.listPromotionsItems),
          pos_cashier_input_discount: orders.pos_cashier_input_discount ?? 0,
          is_tax_included: orders.is_tax_included ?? false,
          pos_is_shipping: orders.pos_is_shipping ?? false,
          discounts: orders.discounts ?? [],
          promotions: orders.promotions ?? [],
          pricebooks: orders.pricebooks ?? [],
          pos_outlet_discount: orders.pos_outlet_discount ?? 0,
          pos_promotion_discount: orders.pos_promotion_discount ?? 0,
          salesmen_id: orders.salesmen_id ?? 0,
          salesmen_name: orders.salesmen_name ?? '',
          shipping: orders.shipping,
        },
        duePayment: duePayment - Number(orders.grand_total),
      };
    },
    continueDownPayment: (state, action: PayloadAction<DetailOrderTransaction>) => {
      const duePayment: number = action.payload.payments.reduce(
        (acc, cur) => acc + Number(cur.payment_amount),
        0
      );
      return {
        ...state,
        detailOrderHistory: action.payload,
        order: mappingOrderDataContinuePayment(state, action.payload),
        listItemCart: mappingItemsCartFromOrder(action.payload.items, action.payload.is_tax_included),
        duePayment: duePayment - Number(action.payload.grand_total),
        remainingPayment: Number(action.payload.grand_total) - duePayment,
        shippingInfo: action.payload.shipping
          ? action.payload.shipping
          : {
              shipping_full_name: action.payload.shipping_full_name ?? '',
              shipping_phone: action.payload.shipping_phone ?? '',
              shipping_address: action.payload.shipping_address ?? '',
              shipping_area: action.payload.shipping_area ?? '',
              shipping_city: action.payload.shipping_city ?? '',
              shipping_province: action.payload.shipping_province ?? '',
              shipping_post_code: action.payload.shipping_post_code ?? '',
              shipping_cost: action.payload.shipping_cost || 0,
              courier: action.payload.courier ?? '',
            },
        continuePayment: true,
        salesmenInfo: action.payload.salesmen_id
          ? {
              salesmen_id: action.payload.salesmen_id,
              salesmen_name: action.payload.salesmen_name,
              email: '',
              phone: '',
            }
          : null,
        customerInfo: mappingCustomerData(action.payload),
        prevPayments: (action.payload.payments as unknown as IPayments[]) ?? [],
        closure_id: action.payload.closure_id,
        discount_trx_amount: Number(action.payload.service_fee) ?? 0,
        isDiscountPercentTrx: false,
        pos_is_shipping: action.payload.pos_is_shipping ?? false,
        other_cost: Number(action.payload.add_fee) ?? 0,
        isOtherCostPercent: false,
      };
    },
    setPaymentMethod: (state, action: PayloadAction<IPayments>) => {
      const idxPayment = state.listPayments.findIndex(
        (item) => item.payment_id === action.payload.payment_id && item.payment_id !== -5
      );
      const newPayments =
        idxPayment > -1
          ? updatePayment(action.payload, state.listPayments)
          : state.listPayments.concat(action.payload);
      const duePayment: number = newPayments.reduce(
        (acc, cur) => acc + (cur.is_dp ? Number(cur.dp_amount) : Number(cur.payment_amount)),
        0
      );
      return {
        ...state,
        listPayments: newPayments,
        duePayment: getDuePayment(newPayments, Number(state.order.grand_total)),
        order: {
          ...state.order,
          payments: mappingPaymentsOrder(newPayments, duePayment - Number(state.order.grand_total)),
        },
      };
    },
    setPaymentMethodContinuePayment: (state, action: PayloadAction<IPayments>) => {
      const idxPayment = state.listPayments.findIndex(
        (item) => item.payment_id === action.payload.payment_id && item.payment_id !== -5
      );
      const newPayments =
        idxPayment > -1
          ? updatePayment(action.payload, state.listPayments)
          : state.listPayments.concat(action.payload);
      const duePayment: number = newPayments.reduce(
        (acc, cur) => acc + (cur.is_dp ? Number(cur.dp_amount) : Number(cur.payment_amount)),
        0
      );
      return {
        ...state,
        listPayments: newPayments,
        duePayment: getDuePayment(newPayments, Number(state.remainingPayment)),
        order: {
          ...state.order,
          payments: mappingPaymentsOrder(newPayments, duePayment - Number(state.remainingPayment)),
        },
      };
    },
    deletePayment: (state, action: PayloadAction<number>) => {
      const newPayments = state.listPayments.filter((_item, key) => key !== action.payload);
      const duePayment: number = newPayments.reduce(
        (acc, cur) => acc + (cur.is_dp ? Number(cur.dp_amount) : Number(cur.payment_amount)),
        0
      );
      return {
        ...state,
        listPayments: newPayments,
        duePayment: duePayment - Number(state.order.grand_total),
        order: {
          ...state.order,
          payments: mappingPaymentsOrder(newPayments, duePayment - Number(state.order.grand_total)),
        },
      };
    },
    deleteContinuePayment: (state, action: PayloadAction<number>) => {
      const newPayments = state.listPayments.filter((_item, key) => key !== action.payload);
      const duePayment: number = newPayments.reduce(
        (acc, cur) => acc + (cur.is_dp ? Number(cur.dp_amount) : Number(cur.payment_amount)),
        0
      );
      return {
        ...state,
        listPayments: newPayments,
        duePayment: duePayment - Number(state.remainingPayment),
        order: {
          ...state.order,
          payments: mappingPaymentsOrder(newPayments, duePayment - Number(state.remainingPayment)),
        },
      };
    },
    setContactToOrder: (state, action: PayloadAction<ICustomerInfoInput>) => {
      return {
        ...state,
        order: {
          ...state.order,
          customer_name: action.payload.contact_name ?? '',
          customer: {
            b_address: action.payload.s_address ?? '',
            b_area: action.payload.s_area ?? '',
            b_city: action.payload.s_city ?? '',
            b_post_code: action.payload.s_post_code ?? '',
            b_province: action.payload.s_province ?? '',
            contact_id: action.payload.contact_id,
            contact_name: action.payload.contact_name,
            contact_type: action.payload.contact_type,
            email: action.payload.email ?? '',
            location_id: action.payload.location_id ?? 0,
            phone: action.payload.phone ?? '',
            is_update_email: action.payload.is_update_email ?? false,
            category_id: action.payload.category_id ?? -1,
            country_id: action.payload.country_id ?? 45,
            dob: action.payload.dob,
          },
          shipping: state.order.pos_is_shipping
            ? state.order.shipping
            : {
                ...state.order.shipping,
                shipping_full_name: action.payload.contact_name,
                shipping_phone: action.payload.phone ?? '',
              },
        },
      };
    },
    updateItemSlashPrice: (state, action: PayloadAction<SlashPrice>) => {
      const listItemCart = [];
      for (const item of state.listItemCart) {
        if (item.item_id === action.payload.item_id) {
          listItemCart.push({
            ...item,
            pos_slash_price: action.payload.total_slash_price,
          });
        } else {
          listItemCart.push(item);
        }
      }

      return {
        ...state,
        listItemCart,
      };
    },
    setSendStructByEmail: (state, action: PayloadAction<boolean>) => {
      state.sendStructByEmail = action.payload;
    },
    setPosIsShipping: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        pos_is_shipping: action.payload,
        order: {
          ...state.order,
          pos_is_shipping: action.payload,
        },
      };
    },
    setShippingToOrder: (state, action: PayloadAction<IShippingInfo>) => {
      return {
        ...state,
        order: {
          ...state.order,
          shipping: action.payload,
        },
      };
    },
    applySlashPrice: (state, action: PayloadAction<SlashPrice | null>) => {
      const listItemCart = [];
      const listDiscounts = [];

      for (const item of state.listItemCart) {
        const ik = `item_${item.item_id}_${item.child_id}`;
        const slash_price = `slash_price_${ik}`;
        if (action.payload && action.payload[slash_price] !== undefined) {
          listItemCart.push({
            ...item,
            pos_slash_price: action.payload[slash_price].total_slash_price ?? 0,
            slash_price: (action.payload[slash_price] ?? 0) === 0 ? null : action.payload,
          });
          listDiscounts.push({
            promotion_price: action.payload[slash_price].total_slash_price,
            item_id: item.item_id,
            discount_id: action.payload[slash_price].discount_id,
          });
        } else if (action.payload === null) {
          listItemCart.push({
            ...item,
            pos_slash_price: 0,
            slash_price: null,
          });
        } else {
          listItemCart.push(item);
        }
      }

      return {
        ...state,
        listItemCart,
        listDiscounts,
      };
    },
    applyPriceBook: (state, action: PayloadAction<PriceBook | null>) => {
      const listItemCart: IItemCart[] = [];
      for (const item of state.listItemCart) {
        const ik = `item_${item.item_id}_${item.child_id}`;
        const price_book_id = `price_book_id_${ik}`;
        const retail_price = `retail_price_${ik}`;
        const min_unit = `min_unit_${ik}`;
        const list_price_book = `list_price_book_${ik}`;
        const slashPrice = `slash_price_${ik}`;

        if (action.payload && action.payload[ik] !== undefined) {
          listItemCart.push({
            ...item,
            sell_price:
              action.payload[retail_price] === 0 || item.is_edit_price
                ? item.sell_price
                : action.payload[retail_price],
            price_book: action.payload[price_book_id]
              ? {
                  price_book_id: action.payload[price_book_id],
                  retail_price: action.payload[retail_price],
                  item_id: item.item_id,
                  min_unit: action.payload[min_unit],
                }
              : null,
            list_price_book: action.payload[list_price_book] ?? null,
            pos_slash_price: item.slash_price
              ? item.slash_price[slashPrice] &&
                item.slash_price[slashPrice].promotion_price > action.payload[retail_price]
                ? 0
                : item.quantity *
                  (action.payload[retail_price] - Number(item.slash_price[slashPrice].promotion_price))
              : item.pos_slash_price,
            slash_price: item.slash_price,
          });
        } else {
          listItemCart.push({
            ...item,
            sell_price: Number(item.sell_price) || item.normal_price,
            price_book: item.price_book || null,
            list_price_book: item.list_price_book || null,
            pos_slash_price:
              item.slash_price &&
              item.slash_price[slashPrice] &&
              item.slash_price[slashPrice].promotion_price > item.normal_price
                ? 0
                : item.pos_slash_price,
            slash_price: item.slash_price,
          });
        }
      }
      return {
        ...state,
        listItemCart,
      };
    },
    setListDiscountItem: (state, action: PayloadAction<SlashPrice>) => {
      const listDiscounts = [];
      for (const _item of state.listItemCart) {
        const ik = `item_${_item.item_id}_${_item.child_id}`;
        const slash_price = `slash_price_${ik}`;
        if (action.payload && action.payload[slash_price] !== undefined) {
          listDiscounts.push({
            promotion_price: action.payload[slash_price].total_slash_price,
            item_id: _item.item_id,
            discount_id: action.payload[slash_price].discount_id,
          });
        }
      }

      return {
        ...state,
        listDiscounts,
      };
    },

    // TODO - Refactoring this function after DEMO on 2022-03-14
    setTransactionPromotion: (state, action: PayloadAction<SetPromotionTransaction>) => {
      const listTransactionPromotion: any = [];
      const { promotion, processPromotion } = action.payload;
      for (const idx in promotion) {
        const promo = promotion[idx];
        const pk = `key_${promo?.rules.promotion_id}`;
        if (processPromotion && processPromotion[pk] !== undefined) {
          listTransactionPromotion[idx] = Object.assign({}, promo, {
            free_items: processPromotion[pk].free_items,
            free_products: processPromotion[pk].free_products,
            free_categories: processPromotion[pk].free_categories,
          });
        } else {
          listTransactionPromotion[idx] = promo;
        }
      }

      return {
        ...state,
        listTransactionPromotion,
      };
    },
    setTotalDetail: (state, action: PayloadAction<ITotalDetail | null>) => {
      return {
        ...state,
        totalDetail: action.payload,
      };
    },
    setPromotionItems: (state, action: PayloadAction<SetPromotionTransaction>) => {
      return {
        ...state,
        listPromotionsItems: action.payload.promotion,
      };
    },
    setPromotionsFreeItems: (state, action: PayloadAction<PromotionActionFreeItem>) => {
      const processPromotions = {
        ...state.processPromotions,
        ...action.payload.newPromotions,
      };

      for (const pk of action.payload.removedPromotions) {
        delete processPromotions[pk];
      }

      return {
        ...state,
        processPromotions,
      };
    },
    setProductFreeItems: (state, action: PayloadAction<ActionChooseFreeItem>) => {
      const { payload } = action;
      const updateProductFree: any = {};
      updateProductFree[payload.key] = {
        ...state.processPromotions[payload.promotion].free_products[payload.key],
        selectedItem: payload.item,
        chosen: true,
      };

      const updateProductPromotions: any = {};
      updateProductPromotions[payload.promotion] = {
        ...state.processPromotions[payload.promotion],
        free_products: Object.assign(
          {},
          state.processPromotions[payload.promotion].free_products,
          updateProductFree
        ),
      };

      let listTransactionsP = updateTransactionPromotions(
        updateProductPromotions[payload.promotion],
        state.listTransactionPromotion
      );

      if (listTransactionsP === null) {
        listTransactionsP = state.listTransactionPromotion;
      }

      return {
        ...state,
        processPromotions: Object.assign({}, state.processPromotions, updateProductPromotions),
        listTransactionPromotion: listTransactionsP,
      };
    },
    setCategoryFreeItems: (state, action: PayloadAction<ActionChooseFreeItem>) => {
      const { payload } = action;
      const updatedCategoryFree: any = {};
      updatedCategoryFree[payload.key] = {
        ...state.processPromotions[payload.promotion].free_categories[payload.key],
        selectedItem: payload.item,
        chosen: true,
      };

      const updatedCategoryPromotion: any = {};
      updatedCategoryPromotion[payload.promotion] = {
        ...state.processPromotions[payload.promotion],
        free_categories: Object.assign(
          {},
          state.processPromotions[payload.promotion].free_categories,
          updatedCategoryFree
        ),
      };

      // populate user selected item to the promotion in listTransactionPromotions
      let listTransactions = updateTransactionPromotions(
        updatedCategoryPromotion[payload.promotion],
        state.listTransactionPromotion
      );
      if (listTransactions === null) {
        listTransactions = state.listTransactionPromotion;
      }

      return {
        ...state,
        processPromotions: Object.assign({}, state.processPromotions, updatedCategoryPromotion),
        listTransactionPromotion: listTransactions,
      };
    },
    updateTotalOrder: (state, action: PayloadAction<number>) => {
      const duePayment: number = state.listPayments.reduce(
        (acc, cur) => acc + (cur.is_dp ? Number(cur.dp_amount) : Number(cur.payment_amount)),
        0
      );
      return {
        ...state,
        order: {
          ...state.order,
          grand_total: action.payload,
        },
        duePayment: duePayment - action.payload,
      };
    },
    updateAddFee: (state, action: PayloadAction<{ otherCost: number; roundMoney: number }>) => {
      return {
        ...state,
        order: {
          ...state.order,
          add_fee: action.payload.otherCost + action.payload.roundMoney,
        },
      };
    },
    updateItemCart: (state, action: PayloadAction<any>) => {
      const { payload } = action;
      const listItemCart = payload.listItemCart.map((item: IItemCart) => {
        const totalAmountPerItem = getAmountItem(item);
        const itemDiscount = getDiscountItem(item, state.listPromotionsItems);
        const itemFinalDiscount = getItemFinalDiscount({
          item,
          promotion: state.listPromotionsItems,
          discountTransaction: payload.totalDetail.salesDiscountAmount,
          settings: payload.settings,
          subTotal: payload.totalDetail.subTotalItem,
        });
        return {
          ...item,
          tax_amount: getItemTax(itemFinalDiscount, item, payload.settings.tax_included),
          disc: Number(((itemDiscount / item.quantity / Number(item.sell_price)) * 100).toFixed(2)) ?? 0,
          amount: totalAmountPerItem - itemFinalDiscount,
        };
      });

      return {
        ...state,
        listItemCart,
      };
    },
    clearPaymentsList: (state: ISalesState) => {
      state.listPayments = [];
      state.isOpenPopup = false;
      state.duePayment = 0 - Number(state.order.grand_total);
    },
    togglePopup: (state: ISalesState, action: PayloadAction<boolean>) => {
      state.isOpenPopup = action.payload;
    },
    setQrisDynamic: (state: ISalesState, action: PayloadAction<IGerateQRISDynamicResponse | null>) => {
      state.qrisDynamic = action.payload;
    },
    setLoadingSendOrder: (state: ISalesState, action: PayloadAction<boolean>) => {
      state.loadingSendOrder = action.payload;
    },
    setAuthorizedDPUser: (state: ISalesState, action: PayloadAction<AuthorizedForm>) => {
      state.order.authorized_user_id = action.payload.user?.user_id;
    },
    updateLoadingCheckout: (state: ISalesState, action: PayloadAction<boolean>) => {
      state.isLoadingCheckout = action.payload;
    },
    updateIsCheckout: (state: ISalesState, action: PayloadAction<boolean>) => {
      state.isCheckout = action.payload;
    },
    saveCurrentPromotion: (state, action: PayloadAction<Array<IPromotions>>) => {
      state.currentListPromotion = action.payload;
    },
    setListAllPromotion: (state, action: PayloadAction<IGetPromotionData[]>) => {
      state.listAllPromotion = action.payload;
    },
    setListDiscountItems: (state, action: PayloadAction<IGetSlashPrice[]>) => {
      state.listDiscountItem = action.payload;
    },
    setListPricebookItems: (state, action: PayloadAction<IGetPricebook[]>) => {
      state.listPricebookItem = action.payload;
    },
    setListSelectedPromotion: (state, action: PayloadAction<IGetPromotionData[]>) => {
      return {
        ...state,
        listSelectedPromotion: action.payload,
      };
    },
    setListPromotionAmount: (state, action: PayloadAction<ResPromotionTransactionAmount[]>) => {
      state.listPromotionAmount = action.payload;
    },
    setListPromotionItem: (state, action: PayloadAction<IGetPromotionData[]>) => {
      state.listPromotionItem = action.payload;
    },
    setListFreeItemV2: (state, action: PayloadAction<IFreeItemsV2>) => {
      const findFreeItem = state.listFreeItemV2.find(
        (i) => i.item_id === action.payload.item_id && i.promotion_id === action.payload.promotion_id
      );

      if (findFreeItem) {
        state.listFreeItemV2 = state.listFreeItemV2.map((f) =>
          f.promotion_id === action.payload.promotion_id && f.item_id === action.payload.item_id
            ? { ...f, qty: f.qty + 1 }
            : f
        );
      } else {
        state.listFreeItemV2 = [...state.listFreeItemV2, action.payload];
      }
    },
    updateListFreeItemV2: (state, action: PayloadAction<IFreeItemsV2[]>) => {
      state.listFreeItemV2 = action.payload;
    },
    setListValidVoucher: (state, action: PayloadAction<Array<IValidVoucherList>>) => {
      state.listValidVoucher = action.payload;
    },
    setIsContact: (state, action: PayloadAction<boolean>) => {
      state.isContact = action.payload;
    },
    setErrorPromotion: (state, action: PayloadAction<string>) => {
      state.errorPromotion = action.payload;
    },
  },
});

export const {
  saveProducts,
  loadItems,
  saveCustomers,
  selectCustomer,
  selectSaleman,
  removeCustomer,
  chooseItem,
  chooseItemSaveTransaction,
  resetOrder,
  removeItem,
  removeItemSplit,
  decrementItem,
  incrementItem,
  createOrderAction,
  updateTotalSavedCart,
  selectSavedCart,
  updateItemDetail,
  updateDetailOrder,
  updateShippingOrder,
  updateQuantityItem,
  checkoutAction,
  continueDownPayment,
  setPaymentMethod,
  deletePayment,
  setContactToOrder,
  removeSaleman,
  updateItemSlashPrice,
  setSendStructByEmail,
  setPosIsShipping,
  setShippingToOrder,
  applySlashPrice,
  applyPriceBook,
  setListDiscountItems,
  setPaymentMethodContinuePayment,
  deleteContinuePayment,
  setTransactionPromotion,
  setPromotionItems,
  setTotalDetail,
  setPromotionsFreeItems,
  setProductFreeItems,
  setCategoryFreeItems,
  updateTotalOrder,
  updateItemCart,
  setItemCart,
  clearPaymentsList,
  togglePopup,
  updateAddFee,
  setQrisDynamic,
  setLoadingSendOrder,
  removeOrderNo,
  setAuthorizedDPUser,
  updateLoadingCheckout,
  updateIsCheckout,
  saveCurrentPromotion,
  setListDiscountItem,
  setListPricebookItems,
  setListAllPromotion,
  setListSelectedPromotion,
  setListPromotionAmount,
  setListPromotionItem,
  setListFreeItemV2,
  updateListFreeItemV2,
  setListValidVoucher,
  setIsContact,
  setErrorPromotion,
  splitItem,
} = salesSlice.actions;

export default salesSlice.reducer;
