import { useNotification, usePromotions } from 'hooks';
import { useDispatch } from 'react-redux';
import {
  applyPriceBook,
  applySlashPrice,
  chooseItem,
  chooseItemSaveTransaction,
  removeItemSplit,
} from 'redux/reducer/sales';
import { IItemCart } from 'types/products.types';

import { useAppSelector } from './redux';

interface PropsStock {
  checkingStockAvailable: (variant: IItemCart, type?: string) => void;
}

const useStock = (): PropsStock => {
  const toastScanQty = 'toast-scan-qty';
  const CLOSE_ALERT_DURATION = 2000; // 2 second
  const isOnline = useAppSelector((state) => state.register.isOnline);
  const dispatch = useDispatch();

  //Redux Store
  const listItemCart = useAppSelector((state) => state.sales.listItemCart);
  const customerInfo = useAppSelector((state) => state.sales.customerInfo);

  const { getSlashPrice, getSlashPriceCartOnline, getPriceBookListByCustomer } = usePromotions();
  const { notification } = useNotification();

  // validate stock and add item to cart
  const checkingStockAvailable = async (variant: IItemCart, feat?: string) => {
    const itemOnCart = listItemCart.find((item) => item.item_id === variant.item_id);
    const mappingItem = itemOnCart
      ? { ...itemOnCart, quantity: itemOnCart.quantity + 1, available: itemOnCart.available }
      : variant;

    // park sale save transaction to indexdb
    if (feat === 'save_transaction')
      dispatch(removeItemSplit({ item_id: variant.item_id, child_id: variant.child_id }));

    // Offline mode validation stock
    if (!isOnline) {
      if (!itemOnCart && variant.available <= 0 && variant.pos_check_stock) {
        notification(toastScanQty, 'Stok habis atau tidak mencukupi', 'warning', CLOSE_ALERT_DURATION);
        return;
      }

      if (
        (variant.available === 0 ||
          variant.available < 0 ||
          Number(itemOnCart?.quantity ?? 0) >= variant.available) &&
        variant.pos_check_stock
      ) {
        notification(toastScanQty, 'Stok habis atau tidak mencukupi', 'warning', CLOSE_ALERT_DURATION);
        return;
      }
    } else {
      if (!itemOnCart && variant.available <= 0 && mappingItem.pos_check_stock) {
        notification(toastScanQty, 'Stok habis atau tidak mencukupi', 'warning', CLOSE_ALERT_DURATION);
        return;
      }

      if (
        (variant.available === 0 ||
          variant.available < 0 ||
          Number(itemOnCart?.quantity ?? 0) >= variant.available) &&
        mappingItem.pos_check_stock
      ) {
        notification(toastScanQty, 'Stok habis atau tidak mencukupi', 'warning', CLOSE_ALERT_DURATION);
        return;
      }
    }

    if (feat === 'save_transaction') {
      variant.is_from_cart = true;
      dispatch(chooseItemSaveTransaction(variant));
    } else {
      dispatch(chooseItem(variant));
    }

    if (feat !== 'save_transaction') {
      let slashPrice = null;
      let priceBook = null;
      const findVariant = listItemCart.find((item) => item.item_id === variant.item_id);
      const tempItemCart = [
        ...listItemCart.filter((item) => item.item_id !== variant.item_id),
        findVariant
          ? {
              ...findVariant,
              quantity: findVariant.quantity + 1,
              normal_price: variant.normal_price ?? variant.sell_price,
            }
          : { ...variant, normal_price: variant.normal_price ?? variant.sell_price },
      ];
      if (isOnline) {
        const dataSlashPrice = await getSlashPriceCartOnline(tempItemCart, customerInfo);
        if (dataSlashPrice.discount && Object.keys(dataSlashPrice.discount).length > 0)
          slashPrice = dataSlashPrice.discount;
        if (dataSlashPrice.pricebook && Object.keys(dataSlashPrice.pricebook).length > 0)
          priceBook = dataSlashPrice.pricebook;
      } else {
        slashPrice = await getSlashPrice(tempItemCart, customerInfo);
        priceBook = await getPriceBookListByCustomer(tempItemCart, customerInfo);
      }

      dispatch(applySlashPrice(slashPrice));
      dispatch(applyPriceBook(priceBook));
    }
  };

  return {
    checkingStockAvailable,
  };
};

export default useStock;
