import { Box } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import EmptyItem from 'assets/images/icon-no-barang.png';
import { CardDetail, CardMain } from 'components/card';
import Pagination from 'components/commons/Pagination';
import GridDisplay from 'components/list/GridDisplay';
import { EmptySearch } from 'components/state';
import LoadingCheckout from 'components/state/LoadingCheckout';
import HeaderMachineInfo from 'components/ui/sales/HeaderMachineInfo';
import HeaderSearchBar from 'components/ui/sales/HeaderSearchBar';
import ProductList from 'components/ui/sales/ProductList';
import { TRANSACTION_SALES_TYPE } from 'constant';
import { useLiveQuery } from 'dexie-react-hooks';
import { useNotification, useOrder, useStock } from 'hooks';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import * as React from 'react';
import { clearCartReturn } from 'redux/reducer/return';
import { resetOrder, updateIsCheckout } from 'redux/reducer/sales';
import productsRequest from 'services/http/products.request';
import cart from 'services/indexdb/cart';
import orders from 'services/indexdb/orders';
import products from 'services/indexdb/products';
import returnOrder from 'services/indexdb/return-order';
import { IProductList } from 'types/products.types';
import { debounce } from 'utils';
import { mappingItemsCart } from 'utils';

const EmptyState = ({ title, subtitle }: { title: string; subtitle: string }) => (
  <EmptySearch icons={EmptyItem} title={title} subtitle={subtitle} />
);

const fetchProduct = async (search: string, page: number, pageSize: number) => {
  let listProduct;
  let totalCount = await products.count();

  if (search !== '') {
    const res = await products.filter(search.replace(/\\/g, ''), page, pageSize);
    listProduct = res.listProduct;
    totalCount = await products.countFilter(search.replace(/\\/g, ''));
  } else {
    const res = await products.get(page, pageSize);
    listProduct = res;
  }

  return {
    totalCount,
    listProduct,
  };
};

const MainSales = () => {
  const [totalCount, setTotalCount] = React.useState<number>(0);
  const [page, setPage] = React.useState<number>(1);
  const [pageSize, setPageSize] = React.useState<number>(15);
  const [search, setSearch] = React.useState<string>('');
  const [cursor, setCursor] = React.useState<number>(0);

  const toastScanItem = 'toast-scan-item';
  const CLOSE_ALERT_DURATION = 2000; // 2 second

  const registerInfo = useAppSelector((state) => state.register.registerInfo);
  const locationPos = useAppSelector((state) => state.register.location);
  const unSyncTransaction = useLiveQuery(() => orders.get('', TRANSACTION_SALES_TYPE.NOT_SYNC), []);
  const unSyncReturn = useLiveQuery(() => returnOrder.getUnsync(''));
  const totalUnSyncTrx = Number(unSyncTransaction?.length) + Number(unSyncReturn?.length) || 0;
  const settings = useAppSelector((state) => state.commons.settings);

  const { createOrder } = useOrder();
  const { checkingStockAvailable } = useStock();
  const { notification } = useNotification();

  const dispatch = useAppDispatch();
  const returnMode = useAppSelector((state) => state.return.returnMode);
  const cartId = useAppSelector((state) => state.sales.cartId);
  const continuePayment = useAppSelector((state) => state.sales.continuePayment);
  const location = useAppSelector((state) => state.register.location);
  const isOnline = useAppSelector((state) => state.register.isOnline);
  const listItemCart = useAppSelector((state) => state.sales.listItemCart);
  const currentClosure = useAppSelector((state) => state.register.currentClosure);
  const isLoadingCheckout = useAppSelector((state) => state.sales.isLoadingCheckout);
  const useBarcode = useAppSelector((state) => state.commons.useBarcode);

  // queryClient to get products from api for Online mode
  const {
    data: listProducts,
    isLoading: isLoadingProducts,
    isFetching: isFetchingProducts,
  } = useQuery({
    queryKey: [
      'list-products',
      { locationId: location?.location_id as number, cursor, pageSize: pageSize, q: search },
    ],
    queryFn: productsRequest.getProductGroups,
    select: (data) => data,
    enabled: isOnline,
    gcTime: 0,
    staleTime: 0,
  });

  // search items with debounce
  const searchItems = debounce(async (value: string) => {
    if (value !== '' || value !== null) {
      if (isOnline) {
        setCursor(0);
      } else {
        setPage(1);
      }
      setSearch(value);
    } else {
      setSearch('');
    }
  }, 300);

  const localListProducts = useLiveQuery<IProductList[]>(async () => {
    if (isOnline) return [];
    const result = await fetchProduct(search, page, pageSize);
    setTotalCount(result.totalCount);
    return result.listProduct;
  }, [search, page, pageSize, isOnline]);

  const productList = React.useMemo(() => {
    if (isOnline) {
      return listProducts?.data || [];
    } else {
      return localListProducts || [];
    }
  }, [isOnline, localListProducts, listProducts]);

  // scan items from searchbar
  const scanItems = async (e: string) => {
    if (e === null || e.trim() === '') return;
    let scanItemProduct;
    if (isOnline && e) {
      scanItemProduct = await productsRequest.scanItems(e, location?.location_id as number);
    }

    const findSnOnCart = listItemCart.find(
      (item) => item.serial_number && item.serial_number.find((sn) => sn.serial_no === e)
    );

    if (listItemCart.length === 0 && currentClosure) createOrder();
    let result: IProductList[] = [];
    if (isOnline && Array.isArray(scanItemProduct)) {
      result = scanItemProduct as IProductList[];
    } else {
      result = await products.filter(e, page, pageSize).then((res) => res.listProduct);
    }
    searchItems('');

    if (result.length === 0) {
      notification(toastScanItem, 'Barang tidak ditemukan.', 'warning', CLOSE_ALERT_DURATION);
      return;
    }

    if (findSnOnCart) {
      notification(toastScanItem, 'Serial Number telah digunakan', 'warning', CLOSE_ALERT_DURATION);
      return;
    }

    const variant = mappingItemsCart(
      result[0],
      location?.rate as number,
      location?.pos_tax as number,
      settings.tax_included,
      e
    );

    checkingStockAvailable(variant);
  };

  const handleSwitch = (search: string) => {
    if ((cursor > 0 || !isOnline) && search) {
      setCursor(0);
      setPage(1);
    }
    setSearch(search ?? '');
  };

  React.useEffect(() => {
    if (continuePayment === true) {
      if (cartId) cart.delete(cartId);
      dispatch(resetOrder());
    }
  }, [cartId, continuePayment]);

  React.useEffect(() => {
    if (returnMode) {
      dispatch(clearCartReturn());
    }
  }, [returnMode]);

  React.useEffect(() => {
    dispatch(updateIsCheckout(false));
  }, []);

  return (
    <CardMain>
      <div className='relative flex h-full w-[60%] max-w-[60%] flex-col bg-white'>
        <HeaderMachineInfo
          totalUnSyncTrx={totalUnSyncTrx}
          locationPos={locationPos}
          registerInfo={registerInfo}
        />
        <HeaderSearchBar
          search={search}
          isLoadingProducts={isLoadingProducts}
          isFetchingProducts={isFetchingProducts}
          isBarcodeMode={useBarcode}
          scanItems={scanItems}
          searchItems={searchItems}
          onRefresh={() => {
            setCursor(0);
            setSearch('');
          }}
          onSwitchBarcodeMode={handleSwitch}
        />
        <div className='flex-1 items-center justify-center overflow-y-auto'>
          {isLoadingCheckout && <LoadingCheckout />}
          {!isLoadingProducts && productList.length === 0 && !search && (
            <EmptyState title='Produk Masih Kosong' subtitle='Harap isi produk terlebih dahulu' />
          )}
          {!isLoadingProducts && productList.length === 0 && search && (
            <EmptyState title='Yah!' subtitle={`"${search}" tidak ditemukan`} />
          )}
          {isLoadingProducts && (
            <GridDisplay isLoading={isLoadingProducts} placeholder={Array.from({ length: 25 })} />
          )}

          <ProductList
            listProducts={productList}
            page={0}
            pageSize={0}
            search={search}
            isLoadingProducts={false}
            setSearch={(search: string) => setSearch(search)}
          />
        </div>
        <div className='flex items-center p-3 shadow-lg-custom'>
          <Pagination
            page={page}
            pageSize={pageSize}
            useCursor={isOnline ? true : false}
            totalPage={isLoadingProducts ? 1 : totalCount ?? 0}
            hasNext={listProducts?.has_more}
            hasPrev={listProducts?.has_previous}
            onNextCursor={() =>
              setCursor(() => (listProducts?.has_more ? (listProducts?.next_cursor as number) : 0))
            }
            onPrevCursor={() =>
              setCursor(() => (listProducts?.has_previous ? (listProducts.prev_cursor as number) : 0))
            }
            onChangePage={(e) => setPage(e.selected + 1)}
            withTotal={false}
            onChangeRow={(e) => {
              setPage(1);
              setPageSize(e);
            }}
          />
        </div>
      </div>
      <Box w={{ base: '45%', lg: '40%' }} position='relative'>
        <CardDetail />
      </Box>
    </CardMain>
  );
};

export default MainSales;
