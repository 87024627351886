import { DeleteIcon } from '@chakra-ui/icons';
import { IconButton, Menu as MenuChakra, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import { EditIcon, MoreIcon } from 'components/icons';
import SplitIcon from 'components/icons/SplitIcon';
import * as React from 'react';

type MenuProps = {
  onEdit: () => void;
  onDelete: () => void;
  onSplit: () => void;
  isFreeItem?: boolean;
  isDisabled?: boolean;
  isSplit?: boolean;
};

const Menu: React.FC<MenuProps> = ({
  onEdit,
  onDelete,
  onSplit,
  isFreeItem,
  isDisabled,
  isSplit,
}: MenuProps) => {
  return (
    <MenuChakra isLazy={true} lazyBehavior='unmount' matchWidth={true} preventOverflow={true}>
      <MenuButton
        size='sm'
        as={IconButton}
        aria-label='Options'
        icon={<MoreIcon />}
        variant='outline'
        isDisabled={isDisabled}
      />
      <MenuList fontSize='13px' fontWeight='800' w='100px'>
        <MenuItem icon={<EditIcon h='18px' w='18px' />} onClick={onEdit}>
          Edit
        </MenuItem>
        {isSplit && (
          <MenuItem icon={<SplitIcon h='18px' w='18px' />} onClick={onSplit}>
            Split
          </MenuItem>
        )}
        {!isFreeItem && (
          <MenuItem icon={<DeleteIcon />} onClick={onDelete} color='red'>
            Hapus
          </MenuItem>
        )}
      </MenuList>
    </MenuChakra>
  );
};

export default Menu;
