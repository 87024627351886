import { Icon, IconProps } from '@chakra-ui/react';
import React from 'react';

const ChildItemIcon = (props: IconProps): JSX.Element => (
  <Icon width='20' height='22' viewBox='0 0 20 22' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M2 14.3126V10.9004H14V14.3126C14 16.8525 14 18.1224 13.219 18.9114C12.4379 19.7004 11.1808 19.7004 8.66664 19.7004H7.33336C4.81918 19.7004 3.5621 19.7004 2.78105 18.9114C2 18.1224 2 16.8525 2 14.3126Z'
      stroke='#163A50'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M2 10.8992L2.57692 9.66845C3.02436 8.71392 3.24807 8.23666 3.70154 7.96794C4.155 7.69922 4.73667 7.69922 5.9 7.69922H10.1C11.2634 7.69922 11.845 7.69922 12.2985 7.96794C12.7519 8.23666 12.9757 8.71392 13.423 9.66845L14 10.8992'
      stroke='#163A50'
      strokeWidth='1.5'
      strokeLinecap='round'
    />
    <path d='M6.7998 13.2988H9.1998' stroke='#163A50' strokeWidth='1.5' strokeLinecap='round' />
    <path
      d='M13 4.80573V3.09961H19V4.80573C19 6.07565 19 6.71061 18.6095 7.10509C18.219 7.49961 17.5904 7.49961 16.3333 7.49961H15.6667C14.4096 7.49961 13.781 7.49961 13.3905 7.10509C13 6.71061 13 6.07565 13 4.80573Z'
      stroke='#163A50'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M13 3.1L13.2885 2.48462C13.5122 2.00735 13.624 1.76872 13.8508 1.63436C14.0775 1.5 14.3683 1.5 14.95 1.5H17.05C17.6317 1.5 17.9225 1.5 18.1492 1.63436C18.376 1.76872 18.4878 2.00735 18.7115 2.48462L19 3.1'
      stroke='#163A50'
      strokeLinecap='round'
    />
    <path d='M15.3999 4.29883H16.5999' stroke='#163A50' strokeLinecap='round' />
  </Icon>
);

export default React.memo(ChildItemIcon);
