import { Select, useMediaQuery } from '@chakra-ui/react';
import EmptyCartSaved from 'assets/svg/empty-cart-saved.svg';
import Pagination from 'components/commons/Pagination';
import { SearchBar } from 'components/forms';
import { EmptySearch } from 'components/state';
import { TableHistoryTransaksi } from 'components/table';
import { useAppSelector } from 'hooks/redux';
import React from 'react';
import { DetailOrderTransaction } from 'types';
import { IDetailTransaction, TransactionFragment } from 'types/transaction.types';
import { getPagination } from 'utils';

interface SalesProps {
  searchOnline: string;
  page: number;
  setPage: (page: number) => void;
  setPageSize: (pageSize: number) => void;
  pageSize: number;
  transactionsList: IDetailTransaction[];
  setDetailOrder: (detailOrder: DetailOrderTransaction) => void;
  onlineTransaction?: TransactionFragment;
  onSearch: (value: string) => void;
  onSelectType?: (value: number) => void;
}

const Sales: React.FC<SalesProps> = ({
  searchOnline,
  page,
  pageSize,
  setPage,
  setPageSize,
  transactionsList,
  setDetailOrder,
  onlineTransaction,
  onSearch,
  onSelectType,
}: SalesProps) => {
  const paginationRef = React.useRef<any>();
  const isOnline = useAppSelector((state) => state.register.isOnline);
  const currentClosure = useAppSelector((state) => state.register.currentClosure);
  const currentLocation = useAppSelector((state) => state.register.location);
  const [isLargeScreen] = useMediaQuery('(min-width: 978px)');

  const filterTransactionList = transactionsList
    ? transactionsList.filter((order) => {
        if (
          order.closure_id === Number(currentClosure?.closure_id ?? 0) &&
          order.location_id === Number(currentLocation?.location_id ?? 0)
        )
          return order;
      })
    : [];

  const paginateMemo = React.useMemo(() => {
    if ((searchOnline === '' || !isOnline) && filterTransactionList) {
      const listOrder = getPagination(filterTransactionList, page, pageSize).sort((a, b) => {
        return new Date(b.transaction_date).getTime() - new Date(a.transaction_date).getTime();
      });
      return listOrder;
    } else if (isOnline && searchOnline !== '') {
      return onlineTransaction && onlineTransaction.data
        ? onlineTransaction.data.filter((order) => {
            order.closure_id = Number(currentClosure?.closure_id);
            order.location_id = Number(currentLocation?.location_id);

            if (
              order.closure_id === Number(currentClosure?.closure_id ?? 0) &&
              order.location_id === Number(currentLocation?.location_id ?? 0)
            )
              return order;
          })
        : [];
    }
  }, [page, pageSize, transactionsList, searchOnline, onlineTransaction]);

  return (
    <div className='flex h-full w-full flex-col rounded-lg'>
      <div className='flex items-center gap-3 pb-2'>
        <SearchBar
          placeholder='Temukan Transaksi'
          onSearch={(e) => onSearch(e.target.value)}
          value={searchOnline}
          disableEnter
        />
        <Select
          w='auto'
          fontSize={isLargeScreen ? '14px' : '12px'}
          size={isLargeScreen ? 'md' : 'sm'}
          rounded='md'
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
            onSelectType && onSelectType(Number(e.target.value));
            onSearch('');
          }}
        >
          <option value='0'>Semua</option>
          <option value='1'>Belum Lunas (QRIS)</option>
          <option value='2'>Belum Lunas</option>
          <option value='3'>Belum Sync</option>
        </Select>
      </div>
      <div className='flex-1 items-center justify-center overflow-y-auto rounded-md border'>
        {paginateMemo && paginateMemo.length === 0 ? (
          <EmptySearch
            icons={EmptyCartSaved}
            title='Belum ada transaksi'
            subtitle='Anda dapat kembali ke menu penjualan untuk melakukan penjualan'
          />
        ) : (
          <TableHistoryTransaksi
            isLoading={true}
            listTransaction={paginateMemo}
            setOrder={setDetailOrder}
            searchOnline={searchOnline}
            isReturn={false}
          />
        )}
      </div>
      <div className='bg-white pt-3'>
        <Pagination
          p='20px'
          position='absolute'
          refPage={paginationRef}
          onChangePage={(e) => setPage(e.selected + 1)}
          pageSize={pageSize}
          page={page}
          onChangeRow={(e) => {
            setPage(1);
            setPageSize(e);
          }}
          totalPage={
            searchOnline === '' && transactionsList
              ? Number(filterTransactionList.length)
              : Number(onlineTransaction?.totalCount)
          }
        />
      </div>
    </div>
  );
};
export default Sales;
