import { createOrderAction, updateTotalSavedCart } from 'redux/reducer/sales';
import salesRequest from 'services/http/sales.request';
import transactionRequest from 'services/http/transaction.request';
import cart from 'services/indexdb/cart';
import { db } from 'services/indexdb/connection';
import orders from 'services/indexdb/orders';
import products from 'services/indexdb/products';
import { DetailOrderTransaction } from 'types';
import { IPayments } from 'types/common.types';
import { IItemCart } from 'types/products.types';
import { IOrderData, IOrderError, IOrderItem, IResponseOrder } from 'types/sales.types';
import { IDetailTransaction, IPaymentsTransaction } from 'types/transaction.types';
import { mappingLocalOrderItems, mappingOrderData, mappingOrderPromotion } from 'utils';
import { generateSalesorderNo } from 'utils/closures';

import { useAppDispatch, useAppSelector } from './redux';
import useGetClosure from './useGetClosure';
import useNotification from './useNotification';
import usePayments from './usePayments';

type userOderProps = {
  createOrder: () => void;
  getTotalSavedCart: () => void;
  sendOrder: (order: IOrderData, freeItems?: IItemCart[]) => Promise<DetailOrderTransaction>;
  sendOrderError: (order: IOrderData & { errorMessage: string | undefined }) => Promise<IOrderError>;
  cancelOrder: (
    salesorderId: DetailOrderTransaction,
    authorizedUser?: number
  ) => Promise<DetailOrderTransaction>;
  referencePaymentQris: (detailsOrder: DetailOrderTransaction, noRef: string) => Promise<IDetailTransaction>;
  getClosure: () => Promise<boolean>;
};

const useOrder = (): userOderProps => {
  // ** Redux hooks
  const dispatch = useAppDispatch();
  const registerInfo = useAppSelector((state) => state.register.registerInfo);
  const locationStore = useAppSelector((state) => state.register.location);
  const currentClosure = useAppSelector((state) => state.register.currentClosure);
  const profile = useAppSelector((state) => state.auth.profile);
  const sendStructByEmail = useAppSelector((state) => state.sales.sendStructByEmail);
  const listPayments = useAppSelector((state) => state.sales.listPayments);
  const listItemCart = useAppSelector((state) => state.sales.listItemCart);
  const listFreeItemV2 = useAppSelector((state) => state.sales.listFreeItemV2);
  const listPromotionAmount = useAppSelector((state) => state.sales.listPromotionAmount);
  const listPromotionItem = useAppSelector((state) => state.sales.listPromotionItem);
  const isOnline = useAppSelector((state) => state.register.isOnline);

  const { getClosure } = useGetClosure();
  const { notification } = useNotification();
  const { getListAllPayments } = usePayments();

  const createOrder = () => {
    try {
      const salesOrderNo = generateSalesorderNo({
        register_code: registerInfo?.register_code ?? '',
        location_code: locationStore?.location_code ?? '',
      });
      const orderData: IOrderData = {
        salesorder_no: salesOrderNo,
        register_id: Number(registerInfo?.register_id || 0),
        location_id: Number(locationStore?.location_id || 0),
        closure_id: Number(currentClosure?.closure_id || 0),
        user_name: profile?.user.full_name as string,
        is_paid: 0,
        pos_is_shipping: false,
      };
      dispatch(createOrderAction(orderData));
    } catch (error: any) {
      throw new Error(error);
    }
  };

  const getTotalSavedCart = async (): Promise<number> => {
    const result = await cart.get(); // get all cart saved
    dispatch(updateTotalSavedCart(result.length)); // update total cart saved in redux
    return result.length;
  };

  const saveOrUpdate = async (
    order: IOrderData,
    payload: Omit<IOrderData, 'other_cost'>,
    response: {
      orderId: number;
      messageError: string;
      pos_is_unpaid: boolean;
    },
    listPayment: IPayments[]
  ) => {
    const mappingFinalPayments = listPayment.map((item) => {
      const findPayment = payload?.payments?.find(
        (payment) => (payment.no_ref ?? '') === (item.no_ref ?? '') && payment.payment_id === item.payment_id
      );

      if (findPayment) {
        return {
          ...item,
          payment_charge: findPayment.payment_charge,
          payment_amount: findPayment.payment_amount,
          so_payment_id: isOnline ? 1 : 0,
        };
      }

      return item;
    });

    for (const item of listItemCart) {
      if (item.serial_number) {
        await products.updateSerialNumber(item.serial_number, item.item_group_id, item.item_id);
      }
    }

    const paidAmount: number = order.payments
      ? order.payments.reduce((acc, cur) => acc + Number(cur.payment_amount), 0)
      : 0;

    const orderId = response?.orderId ?? 0;
    const isPaid = !isOnline || orderId === 0 ? 0 : 1;
    const isSending = isOnline || orderId === 0 ? false : true;
    payload.pos_is_unpaid = response.pos_is_unpaid;
    const newOrder = Object.assign({}, payload, {
      salesorder_id: orderId,
      is_paid: isPaid,
      is_sending: isSending,
      request_payload: JSON.stringify(payload),
      customer_name: payload.customer?.contact_name,
      items: mappingLocalOrderItems(payload.items as IOrderItem[], listItemCart, listFreeItemV2),
      shipping_cost: Number(payload.shipping?.shipping_cost),
      insurance_cost: Number(payload.shipping?.insurance_cost),
      contact_name: payload.customer?.contact_name,
      contact_email: payload.customer?.email,
      payments: mappingFinalPayments,
      is_send_email: order.is_send_email ? order.is_send_email : sendStructByEmail,
      salesmen_name: order.salesmen_name,
      payment_amount: paidAmount,
      promotions_v2: mappingOrderPromotion(listPromotionAmount, listPromotionItem),
      hasRetur: false,
      return_canceled: false,
      pos_is_unpaid: response.pos_is_unpaid,
      errorMessage: response.messageError,
    });

    const checkOrder = await db.order.where('salesorder_no').equals(order.salesorder_no).first();
    if (checkOrder) {
      return updateOrderData(order.salesorder_no, Number(newOrder.is_canceled), response);
    }

    await orders.add(newOrder);
  };

  const handleOrderError = (orderNo: string, message: string, error: any) => {
    db.order
      .where('salesorder_no')
      .equals(orderNo)
      .modify({ errorMessage: message.concat(error.message) });
  };

  const updateOrderData = (
    orderNo: string,
    isCanceled: number,
    response: { orderId: number; messageError: string; pos_is_unpaid: boolean }
  ) => {
    return db.order
      .where('salesorder_no')
      .equals(orderNo)
      .modify({
        is_paid: !isOnline || response.orderId === 0 ? 0 : 1,
        salesorder_id: response.orderId,
        is_canceled: isCanceled === 1 ? true : false,
        pos_is_unpaid: isOnline ? response.pos_is_unpaid : response.pos_is_unpaid === true ? 1 : 0,
        errorMessage: response.messageError,
      });
  };

  const processOnlineOrder = async (
    payload: Omit<IOrderData, 'other_cost'>,
    isWmsMigrated?: string
  ): Promise<IResponseOrder | null> => {
    const resClosure = await getClosure();
    if (!resClosure) return null;

    payload.is_send_email = payload.is_send_email ? payload.is_send_email : sendStructByEmail;
    const updatedPayload = {
      ...payload,
      // eslint-disable-next-line unused-imports/no-unused-vars
      items: payload?.items?.map(({ child_id, ...rest }) => rest),
    };
    return await salesRequest.sendOrder(updatedPayload, isWmsMigrated);
  };

  // Split this function into two functions
  const sendOrder = async (order: IOrderData, listFreeItems?: IItemCart[]) => {
    let message = '';
    const pos_is_unpaid = order.pos_is_unpaid;
    const payload = mappingOrderData(order, listFreeItems ?? []);
    const isWmsMigrated = localStorage.getItem('is_pos_wms');

    if (payload.items && payload.items.length === 0) {
      notification('', 'List barang kosong. Harap input ulang transaksi.', 'error', 5000);
      throw Error('List barang kosong. Harap input ulang transaksi.');
    }

    let listPaymentOrder = listPayments;
    if (listPaymentOrder.length === 0) {
      listPaymentOrder = await getListAllPayments();
      listPaymentOrder = listPaymentOrder.filter((payment) =>
        payload.payments?.find((p) => p.payment_id === payment.payment_id)
      );
    }

    // save order to indexdb
    await saveOrUpdate(
      order,
      payload,
      {
        orderId: 0,
        messageError: '',
        pos_is_unpaid: pos_is_unpaid ?? false,
      },
      listPaymentOrder
    );

    delete payload.pos_is_unpaid;
    payload.is_send_email = order.is_send_email;

    if (isOnline) {
      processOnlineOrder(payload, isWmsMigrated ?? 'pos')
        .then(async (res) => {
          await saveOrUpdate(
            order,
            payload,
            {
              orderId: res?.orderId ?? 0,
              messageError: message,
              pos_is_unpaid: pos_is_unpaid ?? false,
            },
            listPaymentOrder
          );
          const resContactId = res?.contact.contact_id ?? 0;
          await db.customer
            .where('email')
            .equals(payload.customer?.email ?? '')
            .modify((value) => {
              if (Number(value.contact_id) === 0) value.contact_id = resContactId;
            });
        })
        .catch(async (error) => {
          message = error.response?.data.code;
          if (message === 'Gagal mendapatkan promosi') {
            await db.order.where('salesorder_no').equals(order.salesorder_no).delete();
            notification('', message, 'error', 5000);
            throw Error(message);
          }
          handleOrderError(order.salesorder_no, message, error);
        });
    } else {
      await saveOrUpdate(
        order,
        payload,
        {
          orderId: 0,
          messageError: '',
          pos_is_unpaid: pos_is_unpaid ?? false,
        },
        listPaymentOrder
      );
    }

    return await db.order.where('salesorder_no').equals(order.salesorder_no).first();
  };

  const cancelOrder = async (detailsOrder: DetailOrderTransaction): Promise<DetailOrderTransaction> => {
    try {
      const requestPayload = detailsOrder.request_payload
        ? JSON.parse(detailsOrder.request_payload)
        : detailsOrder;

      const orderPayload = {
        ...requestPayload,
        is_canceled: !isOnline ? 1 : true,
        authorized_user_id: detailsOrder.authorized_user_id,
        is_web: true,
      };

      if (isOnline)
        await salesRequest.cancelOrder(
          Number(detailsOrder.salesorder_id),
          detailsOrder.authorized_user_id,
          orderPayload.is_web
        );

      // update salesorder status to canceled
      await db.order
        .where('salesorder_no')
        .equals(detailsOrder?.salesorder_no)
        .modify({
          is_canceled: !isOnline ? 1 : true,
          is_web: true,
          request_payload: JSON.stringify(orderPayload),
          authorized_user_id: detailsOrder.authorized_user_id,
        });

      const order = await db.order.where('salesorder_no').equals(detailsOrder.salesorder_no).first();

      return Promise.resolve(order);
    } catch (error: any) {
      return Promise.reject(error);
    }
  };

  const referencePaymentQris = async (
    detailsOrder: DetailOrderTransaction,
    noRef: string
  ): Promise<DetailOrderTransaction> => {
    try {
      if (isOnline && detailsOrder.salesorder_id !== 0) {
        const param = {
          salesorder_id: detailsOrder.salesorder_id,
          no_ref: noRef,
        };
        await transactionRequest.updateQrisRefNo(param);
      }
      const requestPayload = detailsOrder.request_payload
        ? JSON.parse(detailsOrder.request_payload)
        : detailsOrder;

      const indexPaymentQris = requestPayload.payments.findIndex(
        (value: IPaymentsTransaction) => value.payment_id === -4
      );
      if (indexPaymentQris > -1) {
        requestPayload.payments[indexPaymentQris].no_ref = noRef;
      }
      await db.order
        .where('salesorder_no')
        .equals(detailsOrder?.salesorder_no)
        .modify({
          payments: requestPayload.payments,
          request_payload: JSON.stringify(requestPayload),
        });
      const order = await db.order.where('salesorder_no').equals(detailsOrder.salesorder_no).first();

      return Promise.resolve(order);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const sendOrderError = async (order: IOrderData & { errorMessage: string | undefined }) => {
    try {
      const paramOrderError = {
        order_error_id: 0,
        store_id: -1,
        ref_no: order.salesorder_no,
        error_message: order?.errorMessage ?? '',
        order_data: JSON.stringify(order),
      };
      let res;
      if (isOnline) {
        res = await salesRequest.sendErrorOrder(paramOrderError);
      }
      return Promise.resolve(res ?? paramOrderError);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  return {
    createOrder,
    getTotalSavedCart,
    sendOrder,
    cancelOrder,
    referencePaymentQris,
    sendOrderError,
    getClosure,
  };
};

export default useOrder;
