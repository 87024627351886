import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Divider,
  Flex,
  Image,
  SimpleGrid,
  Text,
  useDisclosure,
  useMediaQuery,
  VStack,
} from '@chakra-ui/react';
import BadgeProduct from 'components/badge/BadgeProduct';
import BadgeVariant from 'components/badge/BadgeVariant';
import { ModalImage } from 'components/modal';
import { Heading, SubTitle, Title } from 'components/typography';
import React from 'react';
import { IDetailPrice, Thumbnail } from 'types/products.types';
import { currencyFormat } from 'utils';
interface Props {
  product: IDetailPrice;
}

const DetailProduct: React.FC<Props> = (props: Props) => {
  const { product } = props;
  const [isLargeScreen] = useMediaQuery('(min-width: 978px)');

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [showMore, setShowMore] = React.useState(true);
  return (
    <>
      <Box p='16px' border='1px' borderColor='gray.200' borderRadius='4px'>
        <Heading fontSize={isLargeScreen ? '20px' : '16px'} isTruncated>
          {product.item_name}
        </Heading>
        <SubTitle color='jubelio.soft-dark'>SKU: {product.item_code}</SubTitle>
        <Box borderRadius='4px' border='1px' borderColor='gray.200' mt='16px' px='14px' py='20px'>
          <Flex justifyContent='space-between'>
            <VStack justifyContent='flex-start'>
              <Title color='jubelio.purple' fontSize={isLargeScreen ? '20px' : '14px'} w='full'>
                {currencyFormat(
                  product.slash_price && product.slash_price !== 0 ? product.slash_price : product.sell_price
                )}
              </Title>
              {product.slash_price && product.slash_price !== 0 && (
                <Box fontSize={isLargeScreen ? '14px' : '12px'} color='gray.500' fontWeight='normal' as='del'>
                  {currencyFormat(product.sell_price as number)}
                </Box>
              )}
            </VStack>
            <BadgeProduct ml='13px' colorScheme={Number(product.available_stock) > 0 ? 'green' : 'red'}>
              {Number(product.available_stock) > 0
                ? `Stok: ${Number(product.available_stock)}`
                : 'Stok Habis'}
            </BadgeProduct>
          </Flex>
          {product.price_book && (
            <React.Fragment>
              <Divider my='16px' />
              {product.price_book.map((item, index) => (
                <Text key={index} fontSize={{ base: '11px', lg: '14px' }}>
                  Pembelian {`>`} {item.min_unit} Barang: <b>{currencyFormat(item.retail_price)}/Barang</b>
                </Text>
              ))}
            </React.Fragment>
          )}
        </Box>
        {product.variation_values && product.variation_values.length > 0 && (
          <React.Fragment>
            <Divider my='16px' />
            {product.variation_values.map((item, index) => (
              <Flex
                key={index}
                alignItems={{ base: 'flex-start', lg: 'center' }}
                mb={2}
                className='space-y-1'
                fontSize={{ base: '12px', lg: '14px' }}
                flexDir={{ base: 'column', lg: 'row' }}
              >
                <SubTitle color='gray.600'>{item.label}:</SubTitle>
                <BadgeVariant ml={{ base: 0, lg: 4 }}>{item.value}</BadgeVariant>
              </Flex>
            ))}
          </React.Fragment>
        )}
        <Divider my='16px' />
        <Title fontSize='14px'>Deskripsi</Title>
        <SubTitle
          mt='8px'
          noOfLines={showMore ? 2 : 0}
          dangerouslySetInnerHTML={{ __html: product.description }}
          whiteSpace='pre-line'
        />
        {product.description && product.description.length > 200 && (
          <SubTitle
            color='jubelio.blue'
            _hover={{ cursor: 'pointer' }}
            onClick={() => setShowMore((state) => !state)}
          >
            {showMore ? 'Tampilkan semua..' : 'Tampilkan lebih sedikit'}
          </SubTitle>
        )}
      </Box>
      {product.location_stock && (
        <Box p='16px' border='1px' borderColor='gray.200' borderRadius='4px' mt='12px'>
          <Accordion allowMultiple defaultIndex={[0]}>
            <AccordionItem>
              <h2>
                <AccordionButton px='0px'>
                  <Box flex='1' textAlign='left'>
                    <Title fontSize='14px'>Stok Lokasi Lainnya</Title>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4} px='0px'>
                <VStack spacing={2} w='full' alignItems='flex-start'>
                  {product.location_stock.map((item, index) => (
                    <Flex justifyContent='space-between' w='full' key={index}>
                      <Text fontSize='13px'>{item.location_name}</Text>
                      <Text
                        fontSize='13px'
                        fontWeight='bold'
                        color={Number(item.available) > 0 ? 'green.500' : 'red'}
                      >
                        {Number(item.available) > 0 ? item.available : 'Stok Habis'}
                      </Text>
                    </Flex>
                  ))}
                </VStack>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </Box>
      )}
      <Box p='16px' border='1px' borderColor='gray.200' borderRadius='4px' mt='12px'>
        <Heading fontSize='16px'>Gambar Lainnya</Heading>
        <SimpleGrid columns={{ base: 5, sm: 2, md: 3, lg: 4, xl: 5 }} gap={1} mt='10px'>
          {product.thumbnail?.length > 0 &&
            product.thumbnail?.map((item: Thumbnail, index: number) => (
              <Image
                src={item.thumbnail}
                key={index}
                borderRadius='4px'
                border='1px'
                borderColor='gray.100'
                _hover={{ cursor: 'pointer' }}
                onClick={() => {
                  onOpen();
                }}
              />
            ))}
        </SimpleGrid>
      </Box>

      <ModalImage isOpen={isOpen} onClose={onClose} data={product} />
    </>
  );
};

export default DetailProduct;
