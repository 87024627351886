import { Icon, IconProps } from '@chakra-ui/react';
import React from 'react';

const SplitIcon = (props: IconProps): JSX.Element => (
  <Icon width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M5.33333 12.666H3.33333C2.66667 12.666 2 11.9993 2 11.3327V4.66602C2 3.99935 2.66667 3.33268 3.33333 3.33268H5.33333M10.6667 3.33268H12.6667C13.3333 3.33268 14 3.99935 14 4.66602V11.3327C14 11.9993 13.3333 12.666 12.6667 12.666H10.6667M8 2.66602V13.3327'
      stroke='#163A50'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </Icon>
);

export default React.memo(SplitIcon);
