import { DeleteIcon } from '@chakra-ui/icons';
import { Box, BoxProps, Divider, Flex, HStack, IconButton, Text, Tooltip, VStack } from '@chakra-ui/react';
import BadgeProduct from 'components/badge/BadgeProduct';
import { InputNumber } from 'components/forms';
import { EditIcon, TagIcon } from 'components/icons';
import ListTotal from 'components/list/ListTotal';
import { TooltipBatchNumber } from 'components/popover/BatchNumber';
import { TooltipSerialNumber } from 'components/popover/SerialNumber';
import { SubTitle, Title } from 'components/typography';
import { useCommon } from 'hooks';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import React from 'react';
import { decrementItem, incrementItem, removeItem } from 'redux/reducer/sales';
import { IItemReturn } from 'types/return.types';
import { amountPerItemAfterDiscount, currencyFormat } from 'utils';

interface CardItemReturnProps extends BoxProps {
  item: IItemReturn;
  onChangeItem: (item: IItemReturn) => void;
  onChangeQty?: (qty: number) => void;
}

const CardItemReturn: React.FC<CardItemReturnProps> = ({
  item,
  onChangeItem,
  onChangeQty,
  ...rest
}: CardItemReturnProps) => {
  const dispatch = useAppDispatch();
  const { disableEditItem } = useCommon();
  const { profile } = useAppSelector((state) => state.auth);

  const totalDiscount = React.useMemo(() => {
    return Number(item.disc_amount) + Number(item.promotion_price);
  }, [item]);

  return (
    <Box border='1px' borderColor='gray.200' p='12px' w='full' borderRadius='4px' {...rest}>
      <Flex justifyContent='space-between' className='space-x-2' mb='5px' position='relative'>
        <Tooltip label={item.item_name} hasArrow bg='jubelio.black'>
          <Text fontSize='14px' fontWeight='bold' isTruncated w='60%'>
            {item.item_name}
          </Text>
        </Tooltip>
        <Box w='full' textAlign='right'>
          <Title fontSize='14px' color='jubelio.purple'>
            <TagIcon mr={2} fill='jubelio.purple' />
            {currencyFormat(
              item.promotion_price && item.promotion_price !== 0
                ? Number(item.promotion_price)
                : Number(item.sell_price)
            )}
          </Title>
        </Box>
      </Flex>
      <Flex justifyContent='space-between'>
        <SubTitle fontSize='12px'>{item.item_code}</SubTitle>
        {item.promotion_price && item.promotion_price !== 0 ? (
          <Text fontSize='12px' color='gray.500' fontWeight='normal' as='del'>
            {currencyFormat(Number(item.sell_price))}
          </Text>
        ) : undefined}
      </Flex>
      {(profile?.setting?.enable_batch_number || profile?.setting?.enable_serial_number) && (
        <HStack mt={2}>
          {item.batch_number && <BadgeProduct colorScheme='batch'>Batch</BadgeProduct>}
          {item.serial_number && <BadgeProduct colorScheme='serial'>Serial</BadgeProduct>}
          {item.is_bundle && <BadgeProduct colorScheme='bundle'>Bundle</BadgeProduct>}
          {!item.batch_number && !item.serial_number && !item.is_bundle && (
            <BadgeProduct colorScheme='green'>Satuan</BadgeProduct>
          )}
          {item.serial_number && Array.isArray(item.serial_number) && item.serial_number.length > 0 && (
            <TooltipSerialNumber
              placement='right'
              listSn={
                item.serial_number &&
                item.serial_number
                  .map((item) => {
                    return { serial_no: item.serial_no, amount: item.qty };
                  })
                  .filter((obj: any) => obj.serial_no !== '-' && obj.amount > 0)
              }
            />
          )}
          {item.batch_number && Array.isArray(item.batch_number) && item.batch_number.length > 0 && (
            <TooltipBatchNumber
              placement='right'
              listBn={
                item.batch_number &&
                item.batch_number
                  .map((item) => {
                    return { batch_no: item.batch_no, amount: item.qty, qty: item.qty };
                  })
                  .filter((obj: any) => obj.batch_no !== '-' && obj.qty > 0)
              }
            />
          )}
        </HStack>
      )}
      <VStack w='full' alignItems='start' mt={2}>
        {Number(item.disc_amount) !== 0 && (
          <ListTotal
            fontSize='12px'
            color='gray.500'
            leftText={`Diskon`}
            rightText={<Text>{`-${currencyFormat(totalDiscount)}`}</Text>}
          />
        )}
        {(item.tax_amount as number) !== 0 && (
          <ListTotal
            fontSize='12px'
            color='gray.500'
            leftText={`Pajak`}
            rightText={<Text>{currencyFormat(Math.abs(item.tax_amount))}</Text>}
          />
        )}
      </VStack>
      <Divider my={4} bg='gray.200' />
      <HStack spacing={2}>
        <InputNumber
          value={Math.abs(item.qty_in_base)}
          size='sm'
          disabled={disableEditItem}
          textAlign='center'
          onChange={(e) => onChangeQty && onChangeQty(Number(e.target.value))}
          decrement={() => dispatch(decrementItem({ item_id: item.item_id }))}
          increment={() => dispatch(incrementItem({ item_id: item.item_id }))}
        />
        {!disableEditItem && (
          <>
            <IconButton
              aria-label='remove'
              icon={<DeleteIcon color='red.800' />}
              variant='outline'
              size='sm'
              borderColor='red.800'
              onFocus={(e: any) => e.target.blur()}
              onClick={() => dispatch(removeItem(item.item_id))}
            />
            <IconButton
              aria-label='remove'
              borderColor='jubelio.grey100'
              icon={<EditIcon h='20px' w='20px' />}
              variant='outline'
              size='sm'
              onFocus={(e: any) => e.target.blur()}
              onClick={() => onChangeItem(item)}
            />
          </>
        )}
        <Title w='full' ml='auto' textAlign='right'>
          {currencyFormat(Math.abs(amountPerItemAfterDiscount(item)))}
        </Title>
      </HStack>
      {item.notes && (
        <Box w='full' bg='jubelio.cream' mt={3} rounded='4px' p='9px'>
          <Title fontSize='12px' color='jubelio.grey200'>
            Notes
          </Title>
          <SubTitle color='jubelio.black' mt='8px' fontSize='12px'>
            {item.notes}
          </SubTitle>
        </Box>
      )}
    </Box>
  );
};
export default React.memo(CardItemReturn);
