import { TriangleDownIcon } from '@chakra-ui/icons';
import {
  HStack,
  IconButton,
  Image,
  StackProps,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
} from '@chakra-ui/react';
import NotFound from 'assets/images/notfound.png';
import BadgeProduct from 'components/badge/BadgeProduct';
import { InputNumber } from 'components/forms';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import React from 'react';
import { decrementItemReturn, incrementItemReturn } from 'redux/reducer/return';
import { Maybe } from 'types';
import { IItemReturn } from 'types/return.types';
import { currencyFormat, getCurrentTotalItemPrice } from 'utils';
import { getDiscountAmount } from 'utils';

import TableReturnSnBn from './TableReturnSnBn';

interface TableReturnState extends StackProps {
  listItemReturn: Maybe<IItemReturn[]>;
  changeQuantity: (item: IItemReturn, qty: number) => void;
}

const TableReturn: React.FC<TableReturnState> = (props: TableReturnState) => {
  const { listItemReturn, changeQuantity } = props;
  const dispatch = useAppDispatch();
  const { settings } = useAppSelector((state) => state.commons);
  const [hideType, setHideType] = React.useState<boolean>(false);
  const [isExpandSnBn, setIsExpandSnBn] = React.useState<Maybe<number>>(null);

  const tableHeader = React.useMemo(
    () => [
      {
        title: 'Barang',
        isNumeric: false,
        width: '30%',
        colspan: 2,
      },
      {
        title: 'Tipe',
        isNumeric: false,
      },
      {
        title: 'Harga Barang',
        isNumeric: true,
      },
      {
        title: 'Qty',
        isNumeric: false,
      },
      {
        title: 'Dikembalikan',
        isNumeric: false,
      },
      {
        title: 'Nilai Diskon',
        isNumeric: true,
      },
      {
        title: 'Pengembalian',
        isNumeric: true,
      },
    ],
    []
  );

  React.useEffect(() => {
    if (listItemReturn) {
      const findItemSerialBatchNumber = listItemReturn.find(
        (item: IItemReturn) => item.serial_number || item.batch_number
      );
      setHideType(findItemSerialBatchNumber ? false : true);
    }
  }, [listItemReturn]);

  const clickExpand = (index: number) => {
    if (isExpandSnBn === index) {
      setIsExpandSnBn(null);
    } else {
      setIsExpandSnBn(index);
    }
  };

  return (
    <div className='h-[300px] w-full overflow-y-auto rounded-md border bg-white'>
      <Table size='sm' variant='simple'>
        <Thead position='sticky' top={0} zIndex={10}>
          <Tr>
            {tableHeader.map((theader, index) => (
              <Th
                key={`table-header-${index}`}
                bgColor='jubelio.grey100'
                p={4}
                isNumeric={theader.isNumeric}
                borderRightColor='gray.200'
                borderRightWidth='1px'
                width={theader.width}
                colSpan={theader.colspan}
                hidden={theader.title === 'Tipe' ? hideType : false}
              >
                <span>{theader.title}</span>
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {listItemReturn &&
            listItemReturn.map((item, index) => {
              const itemTotal = getCurrentTotalItemPrice(item);
              const itemDiscount = getDiscountAmount(item);
              return (
                <React.Fragment key={`return-${index}`}>
                  <Tr
                    fontSize={{ base: '12px', sm: '11px', md: '12px', lg: '14px' }}
                    bg={item.qty_in_base === 0 ? 'jubelio.red100' : 'white'}
                  >
                    {/* TODO: Refactoring this later */}
                    <Td p={0} textAlign={'center'}>
                      {(item.serial_number || item.batch_number) && (
                        <IconButton
                          aria-label='expand'
                          icon={
                            <TriangleDownIcon
                              h='10px'
                              color='jubelio.dark'
                              transform={isExpandSnBn === index ? 'rotate(360deg);' : 'rotate(270deg);'}
                            />
                          }
                          variant='ghost'
                          size='sm'
                          onClick={() => clickExpand(index)}
                        />
                      )}
                    </Td>
                    <Td borderRightColor='gray.200' borderRightWidth='1px'>
                      <HStack spacing={5}>
                        <Image
                          src={item.thumbnail === '/images/no-image-icon2.png' ? NotFound : item.thumbnail}
                          loading='lazy'
                          objectFit='cover'
                          fallbackSrc={NotFound}
                          boxSize='56px'
                        />
                        <VStack w='full' alignItems='flex-start'>
                          <Text fontWeight='bold'>{item.item_name}</Text>
                          <Text>{item.item_code}</Text>
                        </VStack>
                      </HStack>
                    </Td>
                    <Td borderRightColor='gray.200' borderRightWidth='1px' hidden={hideType}>
                      {item.serial_number && <BadgeProduct colorScheme='serial'>Serial</BadgeProduct>}
                      {item.batch_number && <BadgeProduct colorScheme='batch'>Batch</BadgeProduct>}
                      {item.is_bundle && <BadgeProduct colorScheme='bundle'>Bundle</BadgeProduct>}
                      {!item.serial_number && !item.batch_number && !item.is_bundle && (
                        <BadgeProduct colorScheme='green'>Satuan</BadgeProduct>
                      )}
                    </Td>
                    <Td isNumeric borderRightColor='gray.200' borderRightWidth='1px'>
                      <Text fontWeight='bold'>{currencyFormat(item.sell_price)}</Text>
                    </Td>
                    <Td textAlign='center' borderRightColor='gray.200' borderRightWidth='1px'>
                      {Math.abs(Number(item.qty))}
                    </Td>
                    <Td borderRightColor='gray.200' borderRightWidth='1px'>
                      <InputNumber
                        value={Math.abs(item.qty_in_base)}
                        size='sm'
                        maxWidth='100%'
                        textAlign='center'
                        onChange={(e) => changeQuantity(item, Number(e.target.value))}
                        decrement={() =>
                          dispatch(
                            decrementItemReturn({
                              ...settings,
                              item_id: item.item_id,
                              marker_id: item.marker_id ?? 0,
                            })
                          )
                        }
                        increment={() =>
                          dispatch(
                            incrementItemReturn({
                              ...settings,
                              item_id: item.item_id,
                              marker_id: item.marker_id ?? 0,
                            })
                          )
                        }
                        disabled={Boolean(item.batch_number || item.serial_number)}
                      />
                    </Td>
                    <Td isNumeric fontWeight='bold' borderRightColor='gray.200' borderRightWidth='1px'>
                      {currencyFormat(Math.abs(Number(item.disc_amount)))}
                    </Td>
                    <Td isNumeric fontWeight='bold'>
                      {currencyFormat(Math.abs(Number(itemTotal - itemDiscount)))}
                    </Td>
                  </Tr>
                  {item.batch_number && isExpandSnBn === index && (
                    <TableReturnSnBn
                      title='BATCH'
                      sell_price={item.sell_price}
                      listBn={item.batch_number}
                      listSn={null}
                      key={`bn-${index}`}
                      item_id={item.item_id}
                      marker_id={item.marker_id ?? 1}
                    />
                  )}
                  {item.serial_number && isExpandSnBn === index && (
                    <TableReturnSnBn
                      title='SERIAL'
                      sell_price={item.sell_price}
                      listBn={null}
                      listSn={item.serial_number}
                      key={`sn-${index}`}
                      item_id={item.item_id}
                      marker_id={item.marker_id ?? 1}
                    />
                  )}
                </React.Fragment>
              );
            })}
        </Tbody>
      </Table>
    </div>
  );
};

export default TableReturn;
