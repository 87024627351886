import { StackProps, Table, Tbody, Td, Text, Th, Thead, Tooltip, Tr } from '@chakra-ui/react';
import clsx from 'clsx';
import { CircleIcon } from 'components/icons';
import { Title } from 'components/typography';
import { useNotification } from 'hooks';
import React from 'react';
import transactionRequest from 'services/http/transaction.request';
import { IQrisPaymentTransaction } from 'types/transaction.types';
import { currencyFormatNotFraction } from 'utils';
import { convertToLocalDate } from 'utils/datetime';

interface TableHistoryQrisPaymentState extends StackProps {
  listTransaction: IQrisPaymentTransaction[] | undefined;
  setOrder: (order: IQrisPaymentTransaction) => void;
}

const TableHistoryQrisPayment: React.FC<TableHistoryQrisPaymentState> = (
  props: TableHistoryQrisPaymentState
) => {
  const { notification } = useNotification();
  const { listTransaction, setOrder } = props;
  const [activeKey, setActiveKey] = React.useState<number | undefined>(undefined);

  const tableHeader = React.useMemo(
    () => [
      {
        title: 'No. Referensi',
        isNumeric: false,
      },
      {
        title: 'Kode RRN',
        isNumeric: false,
      },
      {
        title: 'Waktu Bayar',
        isNumeric: false,
      },
      {
        title: 'Nominal',
        isNumeric: true,
      },
    ],
    []
  );

  const getOrderDetail = async (data: IQrisPaymentTransaction, index: number) => {
    try {
      const order = await transactionRequest.getQrisPaymentDetail(data.pos_qris_id);
      setOrder(order);
      setActiveKey(index);
      return;
    } catch (error) {
      let message = 'Unknown Error';
      if (error instanceof Error) message = error.message;

      return notification('', message, 'warning', 5000);
    }
  };

  const colorStatus = (order: IQrisPaymentTransaction): string => {
    let color = '';
    if (order.salesorder_no && order.salesorder_no !== '') color = 'green.400';
    else color = 'unsync.800';

    return color;
  };

  return (
    <div className='relative h-full text-sm'>
      <Table size='sm' variant='simple' w='full'>
        <Thead position='sticky' top={0} className='rounded-lg'>
          <Tr>
            {tableHeader.map((theader, index) => (
              <Th key={`table-header-${index}`} bgColor='jubelio.grey100' p={4} isNumeric={theader.isNumeric}>
                <Text as='span' fontSize={['10px', '10px', '11px', '12px']}>
                  {theader.title}
                </Text>
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {listTransaction &&
            listTransaction.length > 0 &&
            listTransaction.map((order, index) => (
              <Tr
                key={index}
                className={clsx('text-sm hover:cursor-pointer hover:bg-red-50', {
                  'bg-red-50': activeKey === index,
                })}
                onClick={() => getOrderDetail(order, index)}
              >
                <Td py={4} fontWeight='bold'>
                  <div className='flex items-center gap-2 pb-1'>
                    <Tooltip
                      label={
                        order.salesorder_no && order.salesorder_no !== ''
                          ? 'Transaksi terverifikasi'
                          : 'Transaksi tidak terdaftar'
                      }
                    >
                      <CircleIcon color={colorStatus(order)} />
                    </Tooltip>
                    <Title fontWeight='bold'>{order.external_id}</Title>
                  </div>
                </Td>
                <Td py={4} fontWeight='bold'>
                  {String(order.no_ref ?? '') === '' ? '-' : order.no_ref}
                </Td>
                <Td>{convertToLocalDate(order.created_date)}</Td>
                <Td isNumeric>{currencyFormatNotFraction(Math.abs(Number(order.amount)))}</Td>
              </Tr>
            ))}
        </Tbody>
      </Table>
    </div>
  );
};

export default TableHistoryQrisPayment;
