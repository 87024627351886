import { Icon, IconProps } from '@chakra-ui/react';
import React from 'react';

const MasterItemIcon = (props: IconProps): JSX.Element => (
  <Icon width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M2 8.61341V5.20117H14V8.61341C14 11.1533 14 12.4232 13.219 13.2121C12.4379 14.0012 11.1808 14.0012 8.66664 14.0012H7.33336C4.81918 14.0012 3.5621 14.0012 2.78105 13.2121C2 12.4232 2 11.1533 2 8.61341Z'
      stroke='#163A50'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M2 5.2L2.57692 3.96923C3.02436 3.0147 3.24807 2.53744 3.70154 2.26872C4.155 2 4.73667 2 5.9 2H10.1C11.2634 2 11.845 2 12.2985 2.26872C12.7519 2.53744 12.9757 3.0147 13.423 3.96923L14 5.2'
      stroke='#163A50'
      strokeWidth='1.5'
      strokeLinecap='round'
    />
    <path d='M6.7998 7.59961H9.1998' stroke='#163A50' strokeWidth='1.5' strokeLinecap='round' />
  </Icon>
);

export default React.memo(MasterItemIcon);
