import {
  Box,
  Button,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Text,
  Textarea,
  VStack,
} from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { CardItemPromotion, CardProductDetail } from 'components/card';
import { Input, InputNumber } from 'components/forms';
import { PresetPopover } from 'components/popover/Preset';
import { alert } from 'constant/messages';
import { useLiveQuery } from 'dexie-react-hooks';
import { useNotification, usePromotions } from 'hooks';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import useItemBatchNumber from 'hooks/useItemBatchNumber';
import useItemSerialNumber from 'hooks/useItemSerialNumber';
import { arraysHaveSameIds } from 'lib/helpers';
import * as React from 'react';
import Select from 'react-select';
import { updateItemDetail } from 'redux/reducer/sales';
import productsRequest from 'services/http/products.request';
import { db } from 'services/indexdb/connection';
import customTheme from 'theme/select-theme';
import { IPresets } from 'types/common.types';
import { IItemCart, IUpdateItemCart } from 'types/products.types';
import { currencyFormat, getSaleTotalAfterItemDiscount } from 'utils';
import { getItemPromotion } from 'utils/promotions';

import { Modal } from '.';

interface ModalChangeItemProps {
  isOpen: boolean;
  onClose: () => void;
  items: IItemCart;
  isFreeItem: boolean;
  onOpenConfirmRemovePromo: () => void;
  setDeletedItem: (param: IUpdateItemCart | null) => void;
}

const ModalChangeItem: React.FC<ModalChangeItemProps> = ({
  isOpen,
  onClose,
  items,
  isFreeItem,
  onOpenConfirmRemovePromo,
  setDeletedItem,
}: ModalChangeItemProps) => {
  const dispatch = useAppDispatch();
  const { notification } = useNotification();
  const isOnline = useAppSelector((state) => state.register.isOnline);
  const settings = useAppSelector((state) => state.commons.settings);
  const listPromotionsItems = useAppSelector((state) => state.sales.listPromotionsItems);
  const usePromotion = useAppSelector((state) => state.commons.usePromotion);
  const listAllPromotion = useAppSelector((state) => state.sales.listAllPromotion);
  const listItemCart = useAppSelector((state) => state.sales.listItemCart);
  const location = useAppSelector((state) => state.register.location);

  const [dataItem, setDataItem] = React.useState<IItemCart>(items);
  const [isEmpty, setIsEmpty] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);

  const selectRefSn = React.useRef<HTMLDivElement>(null);

  const { data: listSerialBatchNumber, isLoading } = useQuery({
    queryKey: ['serial-batch-number', { locationId: location?.location_id as number, itemId: items.item_id }],
    queryFn: productsRequest.getSerialBatchNumber,
    enabled: isOnline && !!isOpen,
    gcTime: 0,
    refetchOnWindowFocus: false,
  });

  // Custom Hooks
  const { getPromotionItem } = usePromotions();
  const presetDiscount = useLiveQuery<IPresets[]>(() => db.presetsdiscount.toArray(), []);
  const presetTax = useLiveQuery<IPresets[]>(() => db.presetstax.toArray(), []);
  const {
    optionsSerialNumber,
    selectedSerialNumber,
    onChangeSerialNumber,
    onCountSerialQty,
    onChangeQtySerialNumber,
  } = useItemSerialNumber({
    locationId: location?.location_id as number,
    isOnline,
    isOpen,
    isFetching: isLoading,
    itemCart: items,
    dataOptions: listSerialBatchNumber?.data.serial_number || [],
  });
  const {
    optionsBatchNumber,
    selectedBatchNumber,
    onChangeBatchNumber,
    onChangeBatchNumberQty,
    onCountBatchQty,
    addNewBatchNumber,
  } = useItemBatchNumber({
    locationId: location?.location_id as number,
    isOnline,
    isOpen,
    isFetching: isLoading,
    itemCart: items,
    dataOptions: listSerialBatchNumber?.data.batch_number || [],
  });

  const serialNumberSelected = React.useMemo(() => {
    return selectedSerialNumber.map((sn) => ({ value: sn.serial_no, label: sn.serial_no }));
  }, [selectedSerialNumber]);

  const batchNumberSelected = React.useMemo(() => {
    return selectedBatchNumber.map((bn) => ({
      value: bn.batch_no,
      label: bn.batch_no,
      qty: bn.qty,
    }));
  }, [selectedBatchNumber]);

  const itemPromotions = React.useMemo(() => {
    return getItemPromotion(dataItem, listPromotionsItems);
  }, [dataItem, listPromotionsItems]);

  const isRemovedPromo = async (itemCart: IItemCart[]): Promise<boolean> => {
    const masterItem = itemCart.filter((item) => item.is_master);
    const totalAfterDiscount = getSaleTotalAfterItemDiscount(masterItem, []);
    const result = await getPromotionItem(itemCart, totalAfterDiscount ?? 0);

    return arraysHaveSameIds(result.allPromotion, listAllPromotion, 'promotion_id');
  };

  const handleCloseModal = () => {
    onClose();
    setDataItem({} as IItemCart);
    setLoading(false);
  };

  const hasDiscountPromotion = (item: IItemCart) => {
    return (
      item.pos_slash_price > 0 ||
      item.discount_amount > 0 ||
      item.discount_percent > 0 ||
      Number(itemPromotions) > 0
    );
  };

  const onSave = async (values: IItemCart) => {
    setLoading(true);
    if (!values.isDiscountPercent && values.discount_amount > Number(values.sell_price)) {
      setLoading(false);
      return notification('', 'Diskon nominal melebihin harga barang.', 'error', 5000);
    }

    const data = {
      ...values,
      serial_number: selectedSerialNumber.map((sn) => ({
        serial_no: sn.serial_no || '',
        amount: sn.qty,
      })),
      batch_number: selectedBatchNumber.map((bn) => ({ batch_no: bn.batch_no || '', qty: bn.qty })),
      is_edit_price: Number(items.sell_price) !== Number(values.sell_price),
    };

    if (data.use_batch_number && settings.enable_require_snbn) {
      if (!data.batch_number) {
        setLoading(false);
        return notification('', 'Batch Number wajib diisi.', 'error', 5000);
      }
      const filterEmptyBatchNo = data.batch_number.filter((item) => item.batch_no !== '');
      if (filterEmptyBatchNo && filterEmptyBatchNo.length <= 0) {
        setLoading(false);
        return notification('', 'Batch Number wajib diisi.', 'error', 5000);
      }
    }

    if (data.use_serial_number && settings.enable_require_snbn) {
      if (!data.serial_number) {
        setLoading(false);
        return notification('', 'Serial Number wajib diisi.', 'error', 5000);
      }
      const filterEmptySerialNo = data.serial_number.filter((item) => item.serial_no !== '');
      if (filterEmptySerialNo && filterEmptySerialNo.length <= 0) {
        setLoading(false);
        return notification('', 'Serial Number wajib diisi.', 'error', 5000);
      }
    }

    if (data.batch_number) {
      const filterEmptyBatchNo = data.batch_number.filter((item) => item.batch_no === '');
      const filterFilledBatchNo = data.batch_number.filter((item) => item.batch_no !== '');
      if (filterEmptyBatchNo.length > 0 && filterFilledBatchNo.length > 0) {
        setLoading(false);
        return notification('', 'Harap isi semua Batch No.', 'error', 5000);
      }
      if (filterFilledBatchNo.length > 0) {
        const sum = filterFilledBatchNo
          .map((a) => Number(a.qty))
          .reduce(function (a, b) {
            return a + b;
          });

        if (sum !== data.quantity) {
          setLoading(false);
          return notification(
            '',
            'Total keseluruhan batch number jumlahnya harus sama dengan jumlah barang yang dibeli',
            'error',
            5000
          );
        }
      }
    }

    if (data.serial_number) {
      const filterEmptySerialNo = data.serial_number.filter((item) => item.serial_no === '');
      const filterFilledSerialNo = data.serial_number.filter((item) => item.serial_no !== '');
      if (filterEmptySerialNo.length > 0 && filterFilledSerialNo.length > 0) {
        setLoading(false);
        return notification('', 'Harap isi semua Serial No.', 'error', 5000);
      }
      const duplicateIds = filterFilledSerialNo
        .map((e) => e['serial_no'])
        .map((e, i, final) => final.indexOf(e) !== i && i)
        .filter((obj: any) => data.serial_number && data.serial_number[obj])
        .map((e: any) => data.serial_number && data.serial_number[e]['serial_no']);

      if (duplicateIds.length > 0) {
        setLoading(false);
        return notification('', 'Serial No. tidak boleh memiliki nilai yang sama', 'error', 5000);
      }
    }

    const serialNumber = data.serial_number ? data.serial_number.filter((item) => item.serial_no !== '') : [];

    const batchNumber = data.batch_number
      ? data.batch_number.filter((item) => item.batch_no !== '' && Number(item.qty) > 0)
      : [];

    const modifyListItemCart = listItemCart.map((item) => {
      if (item.item_id === data.item_id) {
        return {
          ...item,
          quantity: data.quantity <= 0 ? 1 : data.quantity,
          amount: Number(data.sell_price) * data.quantity,
          sell_price: Number(data.sell_price),
        };
      }
      return item;
    });

    if (isOnline && usePromotion) {
      try {
        const removedPromo = await isRemovedPromo(modifyListItemCart);
        if (!removedPromo) {
          setDeletedItem({
            item_id: data.item_id,
            qty: data.quantity <= 0 ? 1 : data.quantity,
            action: 'UPDATE-ITEM',
            item: {
              ...data,
              serial_number: serialNumber.length > 0 ? serialNumber : null,
              batch_number: batchNumber.length > 0 ? batchNumber : null,
            },
            is_split: false,
          });
          onOpenConfirmRemovePromo();
          handleCloseModal();
          return;
        }
      } catch (error) {
        let message = 'Unknown Error';
        if (error instanceof Error) message = error.message;
        notification('', message, 'error');
        setLoading(false);
      }
    }

    dispatch(
      updateItemDetail({
        ...data,
        serial_number: serialNumber.length > 0 ? serialNumber : null,
        batch_number: batchNumber.length > 0 ? batchNumber : null,
      })
    );
    setLoading(false);
    handleCloseModal();
  };

  const selectPresetDiscount = (value: number, type: number) => {
    const discountUpdate =
      Number(type) === 1
        ? { discount_percent: value, isDiscountPercent: true }
        : { discount_amount: value, isDiscountPercent: false };

    setDataItem({
      ...dataItem,
      ...discountUpdate,
    });
  };

  const incrementsQty = (item: IItemCart) => {
    if (!dataItem) return;

    const newQuantity = dataItem.quantity + 1;
    const isStockAvailable = !dataItem.pos_check_stock || newQuantity <= Number(item.available);

    if (!isStockAvailable) {
      setIsEmpty(true);
      notification('', alert.stock_empty, 'warning');
      return;
    }

    setDataItem({ ...dataItem, quantity: newQuantity });
    setIsEmpty(false);

    if (dataItem.use_serial_number && selectedSerialNumber) {
      onCountSerialQty('increase', 1);
    }
  };

  const decrementQty = (item: IItemCart) => {
    if (!dataItem) return;

    const newQuantity = Number(dataItem.quantity) <= 1 ? 1 : Number(item.quantity) - 1;
    setDataItem({ ...dataItem, quantity: newQuantity });

    if (dataItem.use_serial_number && selectedSerialNumber && Number(dataItem.quantity) > 1) {
      onCountSerialQty('decrease', 1);
    }
  };

  const handleChangeQtySerialNumber = React.useCallback(
    (value: number, qtyOnCart: number) => onChangeQtySerialNumber(value, qtyOnCart),
    [selectedSerialNumber]
  );

  const handleChangeSerialNumber = React.useCallback(
    (value: string, index: number) => onChangeSerialNumber(value, index),
    [selectedSerialNumber]
  );

  const handleChangeBatchNumber = React.useCallback(
    (value: string, index: number) => onChangeBatchNumber(value, index),
    [selectedBatchNumber]
  );

  const handleChnageQtyBatchNumber = React.useCallback(
    (value: number, index: number) => onChangeBatchNumberQty(value, index),
    [selectedBatchNumber]
  );

  const onChangeQty = (value: number, item: IItemCart | null) => {
    if (!item) return;

    if (!Number.isInteger(value) || value <= 0) return;

    const newQuantity = Number(value);

    if (item.pos_check_stock && newQuantity > Number(item.available)) {
      setIsEmpty(true);
      notification('', 'Stok habis atau tidak mencukupi', 'warning');
      return;
    }

    const updatedItem = { ...dataItem, quantity: newQuantity };
    setDataItem(updatedItem);
    setIsEmpty(false);

    if (dataItem.use_serial_number && selectedSerialNumber) {
      handleChangeQtySerialNumber(newQuantity, dataItem.quantity);
    }
  };

  const onChangeDiscount = (e: React.ChangeEvent<HTMLInputElement>, _item: IItemCart) => {
    const { isDiscountPercent } = _item;
    const newValue = isDiscountPercent ? Number(e.target.value) : Number(e);
    if (isNaN(newValue)) return;
    const maxDiscount = isDiscountPercent ? 100 : Number(items.sell_price);
    const discountKey = isDiscountPercent ? 'discount_percent' : 'discount_amount';

    const clampedValue = Math.max(0, Math.min(newValue, maxDiscount));

    setDataItem((prevItem) => {
      const updatedItem = {
        ...prevItem,
        [discountKey]: clampedValue,
      };

      if (clampedValue !== newValue) {
        console.warn(`Discount ${isDiscountPercent ? 'percentage' : 'amount'} clamped to valid range`);
      }

      return updatedItem;
    });
  };

  React.useEffect(() => {
    if (isOpen) {
      setDataItem(items);
      setIsEmpty(false);
    }
  }, [isOpen]);

  return (
    <Modal isShow={isOpen} onClose={handleCloseModal} title='Ubah Barang' className='max-w-xl'>
      <Modal.Body ref={selectRefSn}>
        {!dataItem.is_master && <Text mb='2'>Barang Pecahan</Text>}
        <CardProductDetail item={items} />
        <VStack spacing={4}>
          <FormControl display='flex' alignItems='center'>
            <FormLabel w='92px' htmlFor='name' fontSize='14px'>
              Harga
            </FormLabel>
            {settings.allow_price_change ? (
              <Input
                isgrouped
                labelGroup='Rp'
                placeholder='Enter amount'
                format='currency'
                name='initialCash'
                onChange={(e) =>
                  setDataItem({
                    ...dataItem,
                    sell_price: Number(e) === 0 ? 1 : e,
                  })
                }
                value={Number(dataItem?.sell_price)}
                disabled={isFreeItem}
              />
            ) : (
              <Text ml='-3'>{currencyFormat(dataItem?.sell_price as number)}</Text>
            )}
          </FormControl>
          <FormControl display='flex' alignItems='center'>
            <FormLabel w='92px' htmlFor='notes' fontSize='14px'>
              Jumlah
            </FormLabel>
            <Box w='full'>
              <InputNumber
                value={dataItem?.quantity ?? 0}
                onChange={(e) => onChangeQty(Number(e.target.value), dataItem)}
                decrement={() => decrementQty(dataItem)}
                increment={() => incrementsQty(dataItem)}
                disabled={isFreeItem}
              />
              {isEmpty && <FormHelperText>Stock terisa {dataItem.available}</FormHelperText>}
            </Box>
          </FormControl>
          {settings.allow_discount_item_change && (
            <FormControl display='flex' alignItems='center'>
              <FormLabel w='92px' htmlFor='diskon' fontSize='14px'>
                Diskon
              </FormLabel>
              <Flex w='full' gridGap={2}>
                <PresetPopover
                  placement='auto'
                  name='Preset'
                  presets={presetDiscount ?? []}
                  selectPreset={selectPresetDiscount}
                  isDisable={isFreeItem}
                />
                <Box w='full'>
                  <Flex w='full'>
                    <Button
                      roundedRight={0}
                      variant={!dataItem.isDiscountPercent ? 'primary' : 'outline'}
                      px={4}
                      onClick={() => setDataItem({ ...dataItem, isDiscountPercent: false })}
                      isDisabled={isFreeItem}
                    >
                      Rp
                    </Button>
                    <Button
                      roundedLeft={0}
                      roundedRight={0}
                      variant={dataItem.isDiscountPercent ? 'primary' : 'outline'}
                      px={4}
                      onClick={() => setDataItem({ ...dataItem, isDiscountPercent: true })}
                      isDisabled={isFreeItem}
                    >
                      %
                    </Button>
                    <Input
                      width='full'
                      format={dataItem.isDiscountPercent ? 'string' : 'currency'}
                      max={dataItem.isDiscountPercent ? 100 : undefined}
                      borderLeft={0}
                      roundedLeft={0}
                      onChange={(e) => onChangeDiscount(e, dataItem)}
                      value={
                        dataItem.isDiscountPercent ? dataItem.discount_percent : dataItem.discount_amount
                      }
                      disabled={isFreeItem}
                    />
                  </Flex>
                  {!dataItem.isDiscountPercent ? (
                    <Text mt={1} fontSize='sm' fontStyle='italic' color='gray.400'>
                      Diskon nominal akan dikalikan sesuai quantity
                    </Text>
                  ) : null}
                </Box>
              </Flex>
            </FormControl>
          )}
          <FormControl display='flex' alignItems='center'>
            <FormLabel w='92px' htmlFor='pajak' fontSize='14px'>
              Pajak
            </FormLabel>
            <Flex gridGap={2} w='full'>
              <PresetPopover
                placement='left'
                name='Preset'
                presets={presetTax ?? []}
                selectPreset={(value, type) => {
                  setDataItem({
                    ...dataItem,
                    tax_percent: value,
                    tax_id: type,
                  });
                }}
                isDisable={isFreeItem}
              />
              <Input
                isgrouped
                labelGroup='%'
                format='number'
                onChange={(e) => setDataItem({ ...dataItem, tax_percent: e.target.value })}
                value={dataItem?.tax_percent ?? 0}
                isDisabled
                _disabled={{
                  background: 'gray.100',
                }}
              />
            </Flex>
          </FormControl>
          {serialNumberSelected.length > 0 &&
            serialNumberSelected.map((serialNumber, index) => (
              <FormControl display='flex' alignItems='center' key={index}>
                <FormLabel w='92px' htmlFor='serial-no' fontSize='14px'>
                  Serial No.
                </FormLabel>
                <Box w='full'>
                  <Select
                    placeholder='--Pilih Serial No.--'
                    options={optionsSerialNumber}
                    styles={customTheme}
                    classNamePrefix={'react-select'}
                    className='left-0'
                    maxMenuHeight={200}
                    onChange={(e) => handleChangeSerialNumber(e?.value as string, index)}
                    value={{
                      value: serialNumber.value,
                      label: serialNumber.label,
                    }}
                    isClearable={true}
                  />
                </Box>
              </FormControl>
            ))}
          {batchNumberSelected.length > 0 &&
            batchNumberSelected.map((batchNumber, index) => (
              <FormControl display='flex' alignItems='center' key={index}>
                <FormLabel w='92px' htmlFor='serial-no' fontSize='14px'>
                  Batch No.
                </FormLabel>
                <Flex gridGap={2} w='full'>
                  <Box w='full'>
                    <Select
                      placeholder='--Pilih Batch No.--'
                      options={optionsBatchNumber}
                      styles={customTheme}
                      menuPlacement='auto'
                      onChange={(e) => handleChangeBatchNumber(e?.value ?? '', index)}
                      value={{
                        value: batchNumber.value,
                        label: batchNumber.label,
                      }}
                      isClearable={true}
                    />
                  </Box>
                  <InputNumber
                    value={batchNumber.qty || 0}
                    onChange={(e) => handleChnageQtyBatchNumber(Number(e.target.value), index)}
                    decrement={() => onCountBatchQty('decrease', index)}
                    increment={() => onCountBatchQty('increase', index)}
                    w='full'
                  />
                </Flex>
              </FormControl>
            ))}
          {items.use_batch_number && selectedBatchNumber && (
            <FormControl display='flex' alignItems='center'>
              <FormLabel w='80px'></FormLabel>
              <button onClick={addNewBatchNumber}>
                <Text as='button' color='system.blue' fontSize={14}>
                  + Tambah Batch No
                </Text>
              </button>
            </FormControl>
          )}
          <FormControl display='flex' alignItems='center'>
            <FormLabel w='92px' htmlFor='notes' fontSize='14px'>
              Catatan
            </FormLabel>
            <Textarea
              id='notes'
              value={dataItem.notes}
              name='notes'
              onChange={(e) => setDataItem({ ...dataItem, notes: e.target.value })}
              disabled={isFreeItem}
            />
          </FormControl>
        </VStack>
        {hasDiscountPromotion(dataItem) && (
          <CardItemPromotion item={items} amountPromotion={itemPromotions} />
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant='primary'
          onClick={() => onSave(dataItem)}
          isDisabled={loading}
          isLoading={loading}
          loadingText='Menyimpan..'
        >
          Simpan
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalChangeItem;
