import {} from '@chakra-ui/button';
import { InfoOutlineIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  CircularProgress,
  CircularProgressLabel,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  IconButton,
  Skeleton,
  Stack,
  Textarea,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { Input } from 'components/forms';
import { CheckCircleIcon, PrintIcon } from 'components/icons';
import { QRISDynamic } from 'components/popover/QRISDynamic';
import QRISDynamicToPrint from 'components/receipt/QRISDynamicToPrint';
import { SubTitle, Title } from 'components/typography';
import config from 'constant';
import { app } from 'constant/messages';
import { useNotification, useSocket } from 'hooks';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import React from 'react';
import QRCode from 'react-qr-code';
import { useReactToPrint } from 'react-to-print';
import { setQrisDynamic } from 'redux/reducer/sales';
import salesRequest from 'services/http/sales.request';
import { IPayments } from 'types/common.types';
import { IFormPaymentMethod } from 'types/sales.types';
import { currencyFormat } from 'utils';
import { QrisCommand } from 'utils/printer/qr_command';

import { Modal } from '.';

interface ModalPaymentCashProps {
  isOpen: boolean;
  duePayments: number;
  paymentsMethod: IPayments[];
  listPayments: IPayments[];
  onClose: (showConfirmation: boolean) => void;
  onSubmit: (values: IFormPaymentMethod) => void;
  isReturn: boolean;
  continuePayment?: boolean;
  remainingPayment?: number;
  printQrisDynamic: () => void;
  isRepayment?: boolean;
}

const ModalPaymentQris: React.FC<ModalPaymentCashProps> = ({
  isOpen,
  onClose,
  onSubmit,
  duePayments,
  paymentsMethod,
  listPayments,
  isReturn,
  continuePayment,
  remainingPayment,
  isRepayment,
}: // printQrisDynamic,
ModalPaymentCashProps) => {
  const {
    isOpen: isOpenConfirmation,
    onOpen: onOpenConfirmation,
    onClose: onCloseConfirmation,
  } = useDisclosure();

  const orders = useAppSelector((state) => state.sales.order);
  const structSetting = useAppSelector((state) => state.commons.structSetting);
  const printer = useAppSelector((state) => state.commons.printer);
  const registerInfo = useAppSelector((state) => state.register.registerInfo);
  const location = useAppSelector((state) => state.register.location);
  const profile = useAppSelector((state) => state.auth.profile);
  const qrisDynamic = useAppSelector((state) => state.sales.qrisDynamic);

  const [data, setData] = React.useState<IFormPaymentMethod>({} as IFormPaymentMethod);
  const [isQrisDynamic, setIsQrisDynamic] = React.useState<boolean>(false);
  const [counter, setCounter] = React.useState<number>(60);
  const [isLoadingQRDynamic, setIsLoadingQRDynamic] = React.useState<boolean>(false);
  const [isLoadingCheckStatus, setIsLoadingCheckStatus] = React.useState<boolean>(false);

  const { socket } = useSocket();
  const isOnline = useAppSelector((state) => state.register.isOnline);
  const { notification } = useNotification();
  const dispatch = useAppDispatch();
  const componentQrisRef = React.useRef(null);

  const generateExternalIdQrCode = (orderNo: string) => {
    const min = Math.pow(10, 4 - 1);
    const max = Math.pow(10, 4);
    const digits = min + Math.floor(Math.random() * (max - min + 1));
    return `${orderNo}-${digits}`;
  };

  const externalId = React.useMemo(() => {
    return generateExternalIdQrCode(orders.salesorder_no ?? '');
  }, [orders]);

  const onSave = (forcedToFinish = false) => {
    const qrisDynamicMethod = paymentsMethod.find((payment) => payment.payment_id === config.QRIS_DYNAMIC);
    if (isQrisDynamic) {
      data.payments = qrisDynamicMethod as IPayments;
    } else {
      const qrisMethod = paymentsMethod.find((payment) => payment.payment_id === config.QRIS_STATIC);
      data.payments = qrisMethod as IPayments;
    }
    if (forcedToFinish) {
      data.no_ref = app.noref_manual_complete_xendit;
      data.qris_id = qrisDynamic?.id ?? '';
      data.qris_string = qrisDynamic?.qr_string ?? '';
    }
    onSubmit(data);
    onClose(false);
    setData({} as IFormPaymentMethod);
  };

  const getPaymentAmount = () => {
    if (listPayments.length > 0) {
      if (isReturn) {
        return duePayments;
      } else {
        return Math.abs(duePayments);
      }
    } else {
      if (continuePayment) {
        return Number(remainingPayment);
      } else {
        return Number(orders.grand_total);
      }
    }
  };

  /**
   * Get lists payments of closure by closure id is open
   */
  const generateQRISDynamic = async () => {
    try {
      if (
        !qrisDynamic ||
        (qrisDynamic && Number(qrisDynamic.amount ?? 0) !== Number(orders.grand_total ?? 0))
      ) {
        setIsLoadingQRDynamic(true);
        const payload = {
          location_id: location?.location_id ?? 0,
          register_id: registerInfo?.register_id ?? 0,
          amount: continuePayment ? String(remainingPayment) : String(orders.grand_total),
          external_id: externalId,
        };
        const res = await salesRequest.GenerateQRISDynamic(payload);
        dispatch(setQrisDynamic(res));
        setIsLoadingQRDynamic(false);
      }
    } catch (err: any) {
      notification('', err.response.data.message, 'error');
      setIsLoadingQRDynamic(false);
    }
  };

  const checkStatusQRISDynamic = async () => {
    try {
      setIsLoadingCheckStatus(true);
      const res = await salesRequest.CheckStatusQRISDynamic(qrisDynamic?.id ?? '');
      setData({
        ...data,
        no_ref: res.payment_details.receipt_id,
        payment_amount: getPaymentAmount(),
        notes: res.payment_details.source,
        qris_id: qrisDynamic?.id ?? '',
      });
      setIsLoadingCheckStatus(false);
    } catch (err: any) {
      notification('', err.response.data.message, 'error');
      setIsLoadingCheckStatus(false);
    }
  };

  const sendDataPrint = (data: Uint8Array) => {
    const socket = new WebSocket(config.WS_CONNECTION);
    socket.onopen = () => {
      socket.send(data);
    };
    socket.onmessage = (event) => {
      const response = JSON.parse(event.data);
      if (response && response.message === 'error') handleReactToPrint();
      if (response && response.message === 'success') {
        console.log('success');
      }
    };
    // if socket is closed or not connected Show print dialog
    socket.onclose = () => {
      socket.close();
      handleReactToPrint();
    };
  };

  const handlePrint = async () => {
    const data = await QrisCommand({
      settingPrint: structSetting,
      location,
      profile,
      paperSize: Number(printer?.paperSize),
      printCopy: Number(printer?.printCopy),
      orderNo: orders.salesorder_no,
      qrisData: qrisDynamic?.qr_string as string,
      grandTotal: currencyFormat(continuePayment ? Number(remainingPayment) : Number(orders.grand_total)),
    });

    sendDataPrint(data);
  };

  const handleReactToPrint = useReactToPrint({
    content: () => componentQrisRef.current,
  });

  React.useEffect(() => {
    if (!isOnline) {
      setIsQrisDynamic(false);
      return;
    }
    const qrisMethod = paymentsMethod.find((payment) => payment.payment_id === config.QRIS_DYNAMIC);
    if (isOpen && isOnline && qrisMethod) {
      setIsQrisDynamic(true);
      generateQRISDynamic();
    }
  }, [isOpen, isOnline]);

  React.useEffect(() => {
    setData({
      ...data,
      payment_amount: getPaymentAmount(),
    });
  }, [isOpen]);

  React.useEffect(() => {
    if (isOpen) {
      const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
      return () => clearInterval(timer as NodeJS.Timeout);
    }
  }, [counter, isOpen]);

  React.useEffect(() => {
    if (socket && isOpen) {
      socket.on('callback-qris', (value) => {
        setData({
          ...data,
          no_ref: value.payment_details.receipt_id,
          payment_amount: getPaymentAmount(),
          notes: value.payment_details.source,
          qris_id: value.qr_code.id,
        });
        setCounter(0);
      });
      return () => {
        socket.off('callback-qris');
      };
    }
  }, [isOpen, socket, isQrisDynamic]);

  React.useEffect(() => {
    if (isOpen && isQrisDynamic && data.no_ref) onSave();
  }, [isOpen, data, isQrisDynamic]);

  return (
    <Modal
      isShow={isOpen}
      onClose={() => {
        onClose(true);
        setCounter(60);
      }}
      title='Pembayaran QRIS'
      disableBackrop={true}
    >
      <Modal.Body>
        <Flex w='full'>
          <Stack w='full'>
            <SubTitle>Total yang harus dibayar</SubTitle>
            <Title my={2} color='jubelio.primary'>
              {currencyFormat(continuePayment ? Number(remainingPayment) : Number(orders.grand_total))}
            </Title>
          </Stack>
        </Flex>
        <Divider my={4} />
        {!isQrisDynamic ? (
          <Flex alignItems='center' gridGap={4}>
            {counter === 0 && !data.no_ref && <InfoOutlineIcon color='jubelio.primary' h='30px' w='30px' />}
            {counter === 0 && data.no_ref && <CheckCircleIcon color='jubelio.primary' h='35px' w='35px' />}
            {counter !== 0 && (
              <CircularProgress value={counter} max={60} color='system.orange'>
                <CircularProgressLabel color='system.orange'>{counter}s</CircularProgressLabel>
              </CircularProgress>
            )}
            <Box>
              <SubTitle>No Referensi</SubTitle>
              <SubTitle>{data.no_ref ?? '-'}</SubTitle>
            </Box>
          </Flex>
        ) : (
          <Skeleton isLoaded={!isLoadingQRDynamic}>
            <Flex alignItems='center' justifyContent='center' gridGap={4}>
              <QRCode value={qrisDynamic?.qr_string ?? ''} size={200} />
            </Flex>
          </Skeleton>
        )}
        <Divider my={4} />
        <VStack spacing={4}>
          {!isQrisDynamic ? (
            <FormControl display='flex' className='space-x-5' alignItems='center'>
              <FormLabel w='92px' htmlFor='name' fontSize='14px'>
                Nominal
              </FormLabel>
              <Input
                id='name'
                name='payment_amount'
                format='currency'
                isgrouped
                labelGroup='Rp'
                onChange={(e) => setData({ ...data, payment_amount: e })}
                value={data.payment_amount}
                isDisabled={true}
              />
            </FormControl>
          ) : null}
          <FormControl display='flex' className='space-x-5' alignItems='center'>
            <FormLabel w='92px' htmlFor='name' fontSize='14px'>
              Notes
            </FormLabel>
            <Textarea
              id='name'
              name='notes'
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                setData({ ...data, notes: e.target.value })
              }
              value={data.notes}
            />
          </FormControl>
        </VStack>
      </Modal.Body>
      <Modal.Footer>
        <HStack justifyContent='flex-end' w='full'>
          {isQrisDynamic && (
            <IconButton
              aria-label='remove'
              icon={<PrintIcon fill='jubelio.black' />}
              variant='outline'
              size='md'
              onClick={() => handlePrint()}
              isDisabled={isLoadingQRDynamic}
            />
          )}
          <Button
            onClick={checkStatusQRISDynamic}
            variant='outline'
            ml={2}
            isDisabled={isOnline ? false : true}
            isLoading={isLoadingCheckStatus}
          >
            Cek Status
          </Button>
          {!isQrisDynamic ? (
            <Button variant='primary' onClick={() => onSave()} type='submit' loadingText='Menyimpan..'>
              Selesai
            </Button>
          ) : (
            !isRepayment && (
              <QRISDynamic
                isOpen={isOpenConfirmation}
                onOpen={onOpenConfirmation}
                onClose={onCloseConfirmation}
                onSubmit={onSave}
              />
            )
          )}
          <Box display='none'>
            {Object.keys(orders).length > 0 ? <QRISDynamicToPrint ref={componentQrisRef} /> : null}
          </Box>
        </HStack>
      </Modal.Footer>
    </Modal>
  );
};

export default React.memo(ModalPaymentQris);
