import { IPaymentClosure } from 'types/closure.types';
import { ICustomerInfo, ICustomerInfoInput, SlashPrice } from 'types/common.types';
import { IItemCart, IProductList, Variant } from 'types/products.types';
import { IPaymentsTransaction } from 'types/transaction.types';

export * from './calculation';
export * from './datetime';
export * from './orders';
export * from './pagination';
export * from './receipt';
export * from './return-calculation';

import IconAkulaku from 'assets/images/channel/icon-akulaku.png';
import IconBlanja from 'assets/images/channel/icon-blanja.png';
import IconBlibli from 'assets/images/channel/icon-blibli.png';
import IconBukalapak from 'assets/images/channel/icon-bukalapak.png';
import IconDealPos from 'assets/images/channel/icon-dealpos.png';
import IconElevenia from 'assets/images/channel/icon-elevenia.png';
import IconEvermos from 'assets/images/channel/icon-evermos.png';
import IconFacebook from 'assets/images/channel/icon-facebook.png';
import IconHappyFresh from 'assets/images/channel/icon-happyfresh.png';
import IconInternal from 'assets/images/channel/icon-internal.png';
import IconJd from 'assets/images/channel/icon-jd.png';
import IconJubelioStore from 'assets/images/channel/icon-jubelio-store.png';
import IconLazada from 'assets/images/channel/icon-lazada.png';
import IconMagento from 'assets/images/channel/icon-magento.png';
import IconMatahariMall from 'assets/images/channel/icon-mataharimall.png';
import IconOlx from 'assets/images/channel/icon-olx.png';
import IconQooo10 from 'assets/images/channel/icon-qoo10.png';
import IconShopee from 'assets/images/channel/icon-shopee.png';
import IconShopify from 'assets/images/channel/icon-shopify.png';
import IconSocialMedia from 'assets/images/channel/icon-socialmedia.png';
import IconTada from 'assets/images/channel/icon-tada.png';
import IconTiktok from 'assets/images/channel/icon-tiktok.png';
import IconTokopedia from 'assets/images/channel/icon-tokopedia.png';
import IconWoo from 'assets/images/channel/icon-webstore.png';
import IconZalora from 'assets/images/channel/icon-zalora.png';
import IconZilingo from 'assets/images/channel/icon-zilingo.png';
import { IItemRewards } from 'types/promotion.types';

/**
 * Debounce a function
 *
 * @param func  Function to debounce
 * @param wait  Time in milliseconds to debounce
 * @param immediate  Whether to call function immediately or wait for debounce
 * @returns Debounced function
 * @example const debounced = debounce(() => console.log('debounced'), 1000);
 */
export function debounce<T extends (...args: any[]) => any>(
  callback: T,
  ms: number
): (...args: Parameters<T>) => Promise<ReturnType<T>> {
  let timer: NodeJS.Timeout | undefined;

  return (...args: Parameters<T>) => {
    if (timer) {
      clearTimeout(timer);
    }
    return new Promise<ReturnType<T>>((resolve) => {
      timer = setTimeout(() => {
        const returnValue = callback(...args) as ReturnType<T>;
        resolve(returnValue);
      }, ms);
    });
  };
}

// format number to IDR currency
export function currencyFormat(num = 0, format = 'Rp'): string {
  const cents = (num - Math.floor(num)).toFixed(2);
  return format + Math.floor(num).toLocaleString().replace(',', '.') + ',' + cents.split('.')[1];
}

export function currencyFormatNotFraction(num = 0, format = 'Rp'): string {
  return format + Math.floor(num).toLocaleString().replace(',', '.');
}
/**
 * Format product list to display in modal variants
 * @param items
 * @returns Formatted product list
 * @example formatItemVariants(items)
 */
export function formatItemVariants(items: IProductList): IProductList {
  const variants = items.variants.map((variant: Variant) => {
    const variationName =
      variant.variation_values &&
      variant.variation_values.map((variation) => {
        return `${variation.value}`;
      });

    return {
      ...variant,
      use_serial_number: items.use_serial_number,
      use_batch_number: items.use_batch_number,
      thumbnail: variant.thumbnail ?? items.thumbnail,
      display_name: `${variationName ? variationName.toString() : ''}`,
      display_item_code: variant.item_code,
      item_category_id: items.item_category_id,
    };
  });

  const listItems = Object.assign({}, items, { variants: variants });
  return listItems;
}

export function formatFreeItemVariants(items: Variant[]): Variant[] {
  if (!items || items.length === 0) return [];
  const variants = items.map((variant: Variant) => {
    const variationName =
      variant.variation_values &&
      variant.variation_values.map((variation) => {
        return `${variation.value}`;
      });

    return {
      ...variant,
      use_serial_number: variant.list_serial_number ? true : false,
      use_batch_number: variant.list_batch_number ? true : false,
      thumbnail: variant.thumbnail ?? '',
      display_name: `${variationName ? variationName.toString() : ''}`,
      display_item_code: variant.item_code,
    };
  });

  return variants;
}

export function formatItemVariantsV2(
  includeItems: IItemRewards[],
  excludeItems: IItemRewards[],
  products: IProductList[]
): IProductList[] {
  const mappingIncludeItem = formatItemReward(includeItems, products);
  const filterExcludeProduct = excludeItems.filter((ex) => ex.item_type !== 'variasi');
  const filterProduct = mappingIncludeItem.filter(
    (item) =>
      !filterExcludeProduct.find(
        (ex) =>
          (ex.target_id === item.item_category_id && ex.item_type === 'category') ||
          (ex.target_id === item.item_group_id && ex.item_type === 'product')
      )
  );

  const filterExcludeVariant = excludeItems.filter((ex) => ex.item_type === 'variasi');
  const filterVariant: IProductList[] = [];
  filterProduct.map((item) => {
    const variants: Variant[] = [];
    item.variants.map((variant) => {
      if (!filterExcludeVariant.find((ex) => ex.target_id === variant.item_id)) {
        variants.push(variant);
      }
    });
    if (variants.length > 0) filterVariant.push({ ...item, variants: variants });
  });

  const mergedArray = [...filterVariant];

  const uniqueArray = Array.from(
    mergedArray
      .reduce((map, obj) => map.set(obj.item_group_id, obj), new Map<number, IProductList>())
      .values()
  );

  return uniqueArray ?? [];
}

function formatVariant(products: IProductList[], itemId: number) {
  const filterVariant: IProductList[] = [];
  products.map((product) => {
    const variants: Variant[] = [];
    product.variants.map((variant) => {
      if (itemId === variant.item_id) {
        variants.push(variant);
      }
    });
    if (variants.length > 0) filterVariant.push({ ...product, variants: variants });
  });
  return filterVariant;
}

function formatItemReward(items: IItemRewards[], products: IProductList[]): IProductList[] {
  if ((items.length === 1 && items[0].target_id === -99) || items.length === 0) {
    return products.map(formatItemVariants);
  }

  const listProduct = items.flatMap((item) => {
    switch (item.item_type) {
      case 'product':
        return products.filter((product) => product.item_group_id === item.target_id);

      case 'category':
        return products.filter((product) => product.item_category_id === item.target_id);

      case 'variasi':
        return formatVariant(products, item.target_id);

      default:
        return [];
    }
  });

  return listProduct.length > 0 ? listProduct.map(formatItemVariants) : [];
}

/**
 * Generate suggested payment money for transaction
 * @param grandTotal
 * @return number
 */
export function getSuggestMoney(grandTotal: number): number[] {
  const arrSuggestedMoney = [];
  const arr = [0];
  let element = 0;
  const surplusMoney = grandTotal % 100000;
  for (let i = 1; i <= 10; i++) {
    arr[i] = arr[i - 1] + 10000;
  }
  for (let index = 0; index < arr.length; index++) {
    element = arr[index];
    if (surplusMoney <= element) {
      break;
    }
  }

  arrSuggestedMoney[0] = grandTotal + element - surplusMoney;
  const surplusMoney1 = arrSuggestedMoney[0] % 100000;
  if (surplusMoney1 === 0) {
    arrSuggestedMoney[1] = arrSuggestedMoney[2] = 0;
  } else if (surplusMoney1 < 50000) {
    arrSuggestedMoney[1] = arrSuggestedMoney[0] + 50000 - surplusMoney1;
    arrSuggestedMoney[2] = arrSuggestedMoney[1] + 50000;
  } else {
    arrSuggestedMoney[1] = arrSuggestedMoney[0] + 100000 - surplusMoney1;
    arrSuggestedMoney[2] = 0;
  }
  return arrSuggestedMoney.filter((value) => value !== 0);
}

/**
 * Create dynamic grid column for suggest money
 * @param suggestMoney
 * @returns {number[]} Array - Columns for suggest money grid component
 */
export function columnSuggestMoney(totalSugestMoney: number[]): number[] {
  const totalLength = totalSugestMoney.length;
  const columns: number[] = [];
  for (let i = 0; i < totalLength; i++) {
    if (i % 3 === 0) {
      columns.push(2);
    } else {
      columns[columns.length - 1] += 1;
    }
  }
  return columns;
}

/**
 * Get payment method from payment transaction list
 * @param paymentId - Payment id to get payment method
 * @param payments - Payment transaction list of indexDB
 * @returns {string} Payment method name (Debit, Kredit, E Money, E-Wallet, Lainnya, Bayar Nanti, Store Kredit, QRIS, Kas)
 */
export function getPaymentsName(payment: IPaymentsTransaction | IPaymentClosure): string {
  let paymentName = 'Kas';

  switch (payment?.payment_type) {
    case 2:
      paymentName = `Debit - ${payment.payment_name}`;
      break;
    case 3:
      paymentName = `Kredit - ${payment.payment_name}`;
      break;
    case 4:
      paymentName = `E Money - ${payment.payment_name}`;
      break;
    case 5:
      paymentName = `E-Wallet - ${payment.payment_name}`;
      break;
    case 6:
      paymentName = `Lainnya - ${payment.payment_name}`;
      break;
    case 8:
      paymentName = `Store Kredit`;
      break;
    case 9:
      paymentName = `QRIS - ${payment.payment_name}`;
      break;
    case 10:
      paymentName = `Voucher`;
      break;
    default:
      paymentName = 'Kas';
  }

  return paymentName;
}

/**
 * Mapping item cart from product list
 *
 * @param items <ProductList>
 * @param posTax <number>
 * @returns <ItemCart>
 * @example mappingItemCart(items, posTax)
 */
export function mappingItemsCart(
  items: IProductList,
  posTax: number,
  taxId: number,
  includeTax: boolean,
  search?: string
): IItemCart {
  const findSn = search && items.variants[0].list_serial_number?.find((sn) => sn.serial_no === search);
  const findBn = search && items.variants[0].list_batch_number?.find((sn) => sn.batch_no === search);
  const result = Object.assign({}, items.variants[0], {
    quantity: 1,
    use_batch_number: items.use_batch_number,
    use_serial_number: items.use_serial_number,
    tax_amount: 0,
    tax_percent: Number(posTax) > 0 ? Number(posTax) : 0,
    tax_id: taxId,
    discount_amount: 0,
    disc: 0,
    discount_percent: 0,
    amount: 0,
    thumbnail: items.variants[0].thumbnail ?? items.thumbnail,
    pos_promotion_discount: 0,
    pos_cashier_input_discount: 0,
    pos_slash_price: items.slash_price
      ? items.slash_price[`total_slash_price_item_${items.variants[0].item_id}`]
      : 0,
    tax_included: includeTax,
    slash_price: items.slash_price,
    sell_price: Number(items.variants[0].sell_price) === 0 ? 1 : Number(items.variants[0].sell_price),
    normal_price: Number(items.variants[0].sell_price) === 0 ? 1 : Number(items.variants[0].sell_price),
    serial_number: findSn ? [{ serial_no: findSn.serial_no, amount: 1 }] : null,
    batch_number: findBn ? [{ batch_no: findBn.batch_no, qty: 1 }] : null,
    item_category_id: items.item_category_id,
    package_length: items.package_length,
    package_width: items.package_width,
    package_height: items.package_height,
    package_weight: items.package_weight,
  });

  return result;
}

/**
 * Mapping variant  from product list
 * @param items <ProductList>
 */
export function mappingItemsCartVariant(
  items: IItemCart,
  posTax: number,
  taxId: number,
  includeTax: boolean,
  search?: string
): IItemCart {
  const findSn = search && items.list_serial_number?.find((sn) => sn.serial_no === search);
  const findBn = search && items.list_batch_number?.find((sn) => sn.batch_no === search);
  const result = Object.assign({}, items, {
    quantity: 1,
    use_batch_number: items.use_batch_number,
    use_serial_number: items.use_serial_number,
    tax_amount: 0,
    tax_percent: Number(posTax) > 0 ? Number(posTax) : 0,
    tax_id: taxId,
    discount_amount: 0,
    discount_percent: 0,
    amount: 0,
    thumbnail: items.thumbnail ?? items.thumbnail,
    pos_promotion_discount: 0,
    pos_cashier_input_discount: 0,
    pos_slash_price: items.slash_price ? items.slash_price[`total_slash_price_item_${items.item_id}_0`] : 0,
    tax_included: includeTax,
    slash_price: items.slash_price,
    sell_price: items.sell_price === 0 ? 1 : items.sell_price,
    serial_number: findSn ? [{ serial_no: findSn.serial_no, amount: 1 }] : null,
    batch_number: findBn ? [{ batch_no: findBn.batch_no, qty: 1 }] : null,
  });

  return result;
}

/**
 * re-calculate slash price for item cart after change quantity or discount
 * @param itemCart
 * @param discount
 * @return number
 */
export function slashPriceWithPricebook(item: IItemCart, pricebook: number): IItemCart {
  const result = Object.assign({}, item, {
    slash_price: {
      ...item.slash_price,
      total_slash_price:
        pricebook === 0
          ? item.slash_price && item.slash_price[`slash_price_item_${item.item_id}_${item.child_id}`]
          : Number(item.quantity) *
            Number(
              item.slash_price
                ? item.slash_price[`slash_price_item_${item.item_id}_${item.child_id}`].promotion_price
                : 0
            ),
    },
    pos_slash_price: item.slash_price
      ? pricebook === 0
        ? Number(item.quantity) *
          Number(item.slash_price[`slash_price_item_${item.item_id}_${item.child_id}`].promotion_price)
        : Number(item.quantity) * Number(item.sell_price)
      : 0,
  });
  return result;
}

export function toggleFullScreen(): void {
  if (!document.fullscreenElement || (!document.mozFullScreen && !document.webkitExitFullscreen)) {
    if (document.documentElement.requestFullscreen) {
      document.documentElement.requestFullscreen();
    } else if (document.documentElement.mozRequestFullscreen) {
      document.documentElement.mozRequestFullscreen();
    } else if (document.documentElement.webkitRequestFullscreen) {
      document.documentElement.webkitRequestFullscreen((<any>Element).ALLOW_KEYBOARD_INPUT);
    }
  } else {
    if (document.cancelFullScreen) {
      document.cancelFullScreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    }
  }
}

interface PaymentStruct {
  cash: number;
  credit: number;
  store_credit: number;
  kembalian: number;
}

const payment = {
  CASH: -1,
  STORE_CREDIT: 3,
};

/**
 * Get payments list for print struct
 *
 * @param payments
 * @returns
 */
export function getPaymentCharge(payments: IPaymentsTransaction[]): PaymentStruct {
  const listMoneyPaid: PaymentStruct = {
    cash: 0,
    credit: 0,
    store_credit: 0,
    kembalian: 0,
  };
  if (payments !== null) {
    for (let i = 0; i < payments.length; i++) {
      if (payments[i].payment_id === payment.CASH) {
        listMoneyPaid.cash = payments[i].payment_amount;
        listMoneyPaid.kembalian += payments[i].payment_charge;
      } else if (payments[i].payment_id === payment.STORE_CREDIT) {
        listMoneyPaid.store_credit = payments[i].payment_amount;
      } else {
        listMoneyPaid.credit += payments[i].payment_amount;
      }
    }
  }

  return listMoneyPaid;
}

/**
 * Generate uuidv4 string
 */
export const uuidv4 = (): string => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

/**
 * Get channel icon from bopis transaction list
 * @param channel_name - channel name to get channel icon
 * @returns {string} Channel Icon
 */
export function getChannelIcon(channel_name: string, is_jubelio_store?: boolean): string {
  let channel_icon = 'INTERNAL';

  switch (channel_name.toUpperCase()) {
    case 'WEBSTORE':
      channel_icon = is_jubelio_store ? IconJubelioStore : IconWoo;
      break;
    case 'INTERNAL':
      channel_icon = IconInternal;
      break;
    case 'LAZADA':
      channel_icon = IconLazada;
      break;
    case 'SHOPEE':
      channel_icon = IconShopee;
      break;
    case 'BUKALAPAK':
      channel_icon = IconBukalapak;
      break;
    case 'TOKOPEDIA':
      channel_icon = IconTokopedia;
      break;
    case 'ELEVENIA':
      channel_icon = IconElevenia;
      break;
    case 'EVERMOS':
      channel_icon = IconEvermos;
      break;
    case 'TADA':
      channel_icon = IconTada;
      break;
    case 'TIKTOK':
      channel_icon = IconTiktok;
      break;
    case 'SHOPIFY':
      channel_icon = IconShopify;
      break;
    case 'ZALORA':
      channel_icon = IconZalora;
      break;
    case 'BLIBLI':
      channel_icon = IconBlibli;
      break;
    case 'DEALPOS':
      channel_icon = IconDealPos;
      break;
    case 'MATAHARIMALL':
      channel_icon = IconMatahariMall;
      break;
    case 'BLANJA':
      channel_icon = IconBlanja;
      break;
    case 'JD':
      channel_icon = IconJd;
      break;
    case 'MAGENTO':
      channel_icon = IconMagento;
      break;
    case 'QOO10':
      channel_icon = IconQooo10;
      break;
    case 'ZILINGO':
      channel_icon = IconZilingo;
      break;
    case 'OLX':
      channel_icon = IconOlx;
      break;
    case 'SOCIALMEDIA':
      channel_icon = IconSocialMedia;
      break;
    case 'JUBELIO-POS':
      channel_icon = 'JUBELIOPOS';
      break;
    case 'AKULAKU':
      channel_icon = IconAkulaku;
      break;
    case 'HAPPYFRESH':
      channel_icon = IconHappyFresh;
      break;
    case 'FACEBOOK':
      channel_icon = IconFacebook;
      break;
    default:
      channel_icon = IconInternal;
  }

  return channel_icon;
}

/** Mapping contact from indexedDb
 * @param contact <ICustomerInfoInput>
 */
export function mappingToSyncContact(contact: ICustomerInfo): ICustomerInfoInput {
  const result: ICustomerInfoInput = {
    contact_name: contact.contact_name,
    email: contact.email,
    phone: contact.phone,
    contact_id: contact.contact_id,
    contact_type: contact.contact_type,
    s_province: contact.s_province,
    s_city: contact.s_city,
    s_area: contact.s_area,
    s_address: contact.s_address,
    s_post_code: contact.s_post_code,
    b_province: '',
    b_city: '',
    b_area: '',
    b_address: '',
    b_post_code: '',
    category_display: contact.category_display,
    category_id: contact.category_id,
    country_id: contact.country_id,
    dob: contact.dob,
  };

  return result;
}

/**
 * Format product to display in listItemCart
 * @param items
 * @returns Formatted item name
 * @example formatItemVariantsName(items)
 */
export function formatItemVariantsName(items: IItemCart): string {
  const variationName =
    items.variation_values &&
    items.variation_values.map((variation, key) => {
      if (key !== variation.value.length - 1) {
        return `${variation.value}`;
      } else {
        return `${variation.value}`;
      }
    });

  return `${items.item_name} ${variationName ? ', '.concat(' ', variationName.toString()) : ''}`;
}

export function slashPriceProperties(
  data: SlashPrice,
  itemId: number,
  newIndex: number,
  isDecrement = false
): SlashPrice {
  const slashPrice = data;
  if (!slashPrice) return {} as SlashPrice;
  const keyItem = isDecrement ? `item_${itemId}_0` : `item_${itemId}_${newIndex}`;
  const slashKeyItem = isDecrement
    ? `slash_price_item_${itemId}_0`
    : `slash_price_item_${itemId}_${newIndex}`;
  const oldSlashKeyItem = isDecrement
    ? `slash_price_item_${itemId}_${newIndex}`
    : `slash_price_item_${itemId}_0`;
  return {
    discount_id: data.discount_id,
    [keyItem]: itemId,
    [slashKeyItem]: slashPrice[oldSlashKeyItem],
  } as SlashPrice;
}

export function isJsonString(text: string): boolean {
  try {
    JSON.parse(text);
  } catch (e) {
    return false;
  }
  return true;
}
