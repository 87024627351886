import EmptyCartSaved from 'assets/svg/empty-cart-saved.svg';
import Pagination from 'components/commons/Pagination';
import { SearchBar } from 'components/forms';
import { EmptySearch } from 'components/state';
import TableHistoryQrisPayment from 'components/table/TableHistoryQrisPayment';
import { useAppSelector } from 'hooks/redux';
import React from 'react';
import { IQrisPaymentTransaction, QrisPaymentFragment } from 'types/transaction.types';

interface QrisProps {
  searchOnline: string;
  page: number;
  setPage: (page: number) => void;
  setPageSize: (pageSize: number) => void;
  pageSize: number;
  setDetailOrder: (detailOrder: IQrisPaymentTransaction) => void;
  onlineTransaction?: QrisPaymentFragment;
  onSearch: (value: string) => void;
}

const Qris: React.FC<QrisProps> = ({
  searchOnline,
  page,
  pageSize,
  setPage,
  setPageSize,
  setDetailOrder,
  onlineTransaction,
  onSearch,
}: QrisProps) => {
  const paginationRef = React.useRef<any>();
  const isOnline = useAppSelector((state) => state.register.isOnline);

  const paginateMemo = React.useMemo(() => {
    console.log(onlineTransaction, 'onlineTransaction');
    return onlineTransaction && onlineTransaction.data ? onlineTransaction.data : [];
  }, [page, pageSize, searchOnline, onlineTransaction, isOnline]);

  return (
    <div className='flex h-full w-full flex-col rounded-lg'>
      <div className='flex items-center pb-2'>
        <SearchBar
          placeholder='Temukan Transaksi'
          onSearch={(e) => onSearch(e.target.value)}
          value={searchOnline}
          disableEnter
        />
      </div>
      <div className='flex-1 items-center justify-center overflow-y-auto rounded-md border'>
        {paginateMemo && paginateMemo.length === 0 ? (
          <EmptySearch
            icons={EmptyCartSaved}
            title='Belum ada pembayaran QRIS'
            subtitle='Anda dapat kembali ke menu penjualan untuk melakukan penjualan dengan pembayaran QRIS'
          />
        ) : (
          <TableHistoryQrisPayment listTransaction={paginateMemo} setOrder={setDetailOrder} />
        )}
      </div>
      <div className='bg-white pt-3'>
        <Pagination
          p='20px'
          position='absolute'
          refPage={paginationRef}
          onChangePage={(e) => setPage(e.selected + 1)}
          pageSize={pageSize}
          page={page}
          onChangeRow={(e) => {
            setPage(1);
            setPageSize(e);
          }}
          totalPage={Number(onlineTransaction?.totalCount)}
        />
      </div>
    </div>
  );
};
export default Qris;
