import { Box, Flex, HStack } from '@chakra-ui/react';
import EmptyCartSaved from 'assets/svg/empty-cart-saved.svg';
import Pagination from 'components/commons/Pagination';
import { SearchBar } from 'components/forms';
import { EmptySearch } from 'components/state';
import { SubTitle, Title } from 'components/typography';
import { useLiveQuery } from 'dexie-react-hooks';
import { useOrder, usePromotions, useStock } from 'hooks';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import React from 'react';
import { clearCartReturn } from 'redux/reducer/return';
import {
  applyPriceBook,
  applySlashPrice,
  resetOrder,
  selectSavedCart,
  setListAllPromotion,
  setListPromotionAmount,
  setListPromotionItem,
  setListValidVoucher,
  updateIsCheckout,
  updateListFreeItemV2,
  updateLoadingCheckout,
} from 'redux/reducer/sales';
import cart from 'services/indexdb/cart';
import { ICartList } from 'types/sales.types';
import { currencyFormat, getPagination } from 'utils';
import { debounce } from 'utils';

import { Modal } from '.';
interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const ModalListCart: React.FC<Props> = (props: Props) => {
  const { isOpen, onClose } = props;
  const [search, setSearch] = React.useState<string>('');
  const [page, setPage] = React.useState<number>(1);
  const [pageSize, setPageSize] = React.useState<number>(5);
  const dispatch = useAppDispatch();
  const isOnlineStore = useAppSelector((state) => state.register.isOnline);
  const listItemCart = useAppSelector((state) => state.sales.listItemCart);
  const listItemReturn = useAppSelector((state) => state.return.listItemReturn);
  const returnMode = useAppSelector((state) => state.return.returnMode);
  const { createOrder, getTotalSavedCart } = useOrder();
  const { getSlashPriceCartOnline, getSlashPrice, getPriceBookListByCustomer } = usePromotions();

  const { checkingStockAvailable } = useStock();

  const listCart = useLiveQuery<ICartList[]>(() => {
    if (search !== '') {
      return cart.filter(search);
    } else {
      return cart.get();
    }
  }, [search]);

  const paginationMemoize = React.useMemo(() => {
    if (listCart) {
      return getPagination(listCart, page, pageSize);
    }
  }, [listCart, page, pageSize]);

  const deletePromotion = () => {
    dispatch(setListAllPromotion([]));
    dispatch(setListPromotionAmount([]));
    dispatch(setListPromotionItem([]));
    dispatch(setListValidVoucher([]));
    dispatch(updateListFreeItemV2([]));
  };

  const resetTransaction = () => {
    dispatch(updateLoadingCheckout(false));
    dispatch(updateIsCheckout(false));
    if (listItemCart?.length > 0 || (listItemReturn && listItemReturn.length > 0)) {
      dispatch(updateLoadingCheckout(false));
      dispatch(updateIsCheckout(false));
      if (returnMode) {
        dispatch(clearCartReturn());
      } else {
        dispatch(resetOrder());
        deletePromotion();
      }
    }
  };

  const chooseTransaction = async (transaction: ICartList) => {
    resetTransaction();
    dispatch(selectSavedCart(transaction));
    createOrder();
    onClose();
    getTotalSavedCart();
    transaction.items.map((item) => {
      checkingStockAvailable(item, 'save_transaction');
    });

    if (isOnlineStore) {
      getSlashPriceCartOnline(transaction.items, transaction.customer).then((result) => {
        dispatch(applySlashPrice(result.discount));
        dispatch(applyPriceBook(result.pricebook));
      });
    } else {
      getSlashPrice(transaction.items, transaction.customer).then((result) => {
        dispatch(applySlashPrice(result));
      });

      getPriceBookListByCustomer(transaction.items, transaction?.customer ?? null).then((result) => {
        dispatch(applyPriceBook(result));
      });
    }
  };

  const searchTransaction = debounce(async (keyword) => {
    setSearch(keyword);
  }, 500);

  return (
    <Modal isShow={isOpen} onClose={onClose} title='Daftar Keranjang'>
      <Modal.Body>
        <HStack justify='space-between' mb='16px'>
          <SearchBar placeholder='Cari transaksi' onSearch={(e) => searchTransaction(e.target.value)} />
        </HStack>
        <Box minH='320px' h='full' overflowY='auto'>
          {paginationMemoize && paginationMemoize.length === 0 && (
            <EmptySearch
              icons={EmptyCartSaved}
              title='Belum ada transaksi'
              subtitle='Anda dapat kembali ke menu penjualan untuk melakukan penjualan'
            />
          )}
          {paginationMemoize && (
            <Box overflowY='auto' maxH='300px'>
              {paginationMemoize.map((item: ICartList, key: number) => (
                <Box
                  key={key}
                  borderBottom='1px'
                  borderColor='gray.200'
                  minH='42px'
                  py={2}
                  px={2}
                  _hover={{ cursor: 'pointer', bg: 'jubelio.red100' }}
                  onClick={() => chooseTransaction(item)}
                >
                  <Flex alignItems='center' justifyContent='space-between'>
                    <Box>
                      <Title fontSize='14px'>{item.name}</Title>
                      <SubTitle>{item.notes ?? '-'}</SubTitle>
                    </Box>
                    <Title>{currencyFormat(item.total)}</Title>
                  </Flex>
                </Box>
              ))}
            </Box>
          )}
        </Box>
      </Modal.Body>
      <Modal.Footer>
        <Pagination
          onChangePage={(e) => setPage(e.selected + 1)}
          totalPage={listCart?.length as number}
          pageSize={pageSize}
          onChangeRow={(e) => setPageSize(e)}
          pageRange={2}
          page={page}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default ModalListCart;
