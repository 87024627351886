export const alert = {
  not_permission: 'Anda tidak diizinkan mengakses Jubelio POS',
  not_integrated_title: 'Jubelio POS belum Terintegrasi',
  not_integrated_text: 'Untuk menggunakan Jubelio POS, Mohon aktifkan integrasinya di Dashboard Jubelio.',
  not_permite_sale_title: 'Anda tidak diizinkan melakukan penjualan',
  not_permite_sale_text: 'Harap hubungi akun manager anda untuk memberikan akses penjualan.',
  error_title: 'Yah! Ada kesalahan',
  error_text: 'Silahkan coba lagi atau hubungi customer support.',
  cashier_closed_title: 'Masih ada Kasir yang aktif.',
  cashier_closed_text: 'apakah ingin menyeselaikan terlebih dahulu atau tetap melanjutkan?',
  error_send_order: 'Terjadi kesalahan saat mengirim transaksi',
  error_create_contact: 'Terjadi kesalahan saat menambahkan customer. Silahkan coba lagi.',
  error_cancel_order: 'Transaksi gagal dibatalkan.',
  success_cancel_order: 'Transaksi berhasil dibatalkan.',
  success_send_email: 'Email berhasil dikirim.',
  success_reference_payment_qris: 'Referensi Pembayaran berhasil.',
  error_reference_payment_qris: 'Terjadi kesalahan saat mereferensikan pembayaran. Silahkan coba lagi.',
  title_not_sync_transaction: 'Terdapat transaksi yang gagal terikirim',
  not_sync_transaction: 'Harap Kirim ulang terlebih dahulu untuk melakukan tutup kasir.',
  title_offline_closure: 'Kamu sedang Offline',
  offline_closure: 'Mohon Periksa kembali Jaringan anda dan kembail ke Halaman ini jika sudah stabil',
  error_send_transaction: 'Terjadi kesalahan saat mengirim transaksi.',
  error_payment_amount_more_than_due_payment: 'Nominal tidak boleh lebih besar dari sisa pembayaran.',
  stock_empty: 'Stock tidak mencukupi.',
  miss_subdistrict: 'Anda belum mengatur Kelurahan, lakukan update lokasi pada Jubelio Omnichannel',
  miss_postcode: 'Anda belum mengatur Kode POS, lakukan update lokasi pada Jubelio Omnichannel',
  miss_subdistrict_recipient: 'Harap isi Kelurahan penerima',
  miss_postcode_recipient: 'Harap isi Kode POS penerima',
  miss_service_category_shipment: 'Harap pilih Kategori Pengiriman',
  miss_courier_shipment: 'Harap pilih Kurir',
  processing_checkout: 'Harap tunggu, transaksi Anda sedang di proses',
};

export const app = {
  disconnect_title: 'Yah!',
  disconnected: 'Kamu Tidak terhubung ke server.',
  reconnected_title: 'Sip!',
  reconnected: 'Kamu sudah terhubung ke server.',
  reconnecting: 'Menghubungkan ke server...',
  reconnect_failed: 'Gagal menghubungkan ke server.',
  reconnect_failed_text: 'Silahkan coba lagi atau hubungi customer support.',
  reconnect_failed_title: 'Yah! Ada kesalahan',
  server_failure_text: 'Terjadi kesahalan pada server. Harap refresh halaman!',
  update_qty: 'Terdapat perubahan data pada produk',
  broadcast: 'Terdapat perubahan banyak data, harap sinkronisasi ulang.',
  discount_update: 'Terdapat perubahan data pada diskon',
  promotion_update: 'Terdapat perubahan data pada promosi',
  preset_update: 'Terdapat perubahan data pada preset',
  payment_update: 'Terdapat perubahan data pada metode pembayaran',
  pricebook_updaet: 'Terdapat perubahan data pada harga',
  location_update: 'Lokasi diperbarui',
  outlet_discount: 'Terdapat perubahan pada diskon outlet',
  outlet_tax: 'Terdapat perubahan pada pajak outlet',
  auth_user_update: 'Terdapat perubahan data pada Otorisasi Pengguna',
  noref_manual_complete_xendit: 'Pembayaran diselesaikan Manual oleh Kasir',
};

export const modal = {
  title_tutup_kasir: 'Tutup Kasir',
  subtitle_tutup_kasir: 'Anda yakin data keuangan yang ada pada sistem sudah sesuai dengan yang aslinya?',
  cancel_text: 'Cek Kembali',
  title_cancel_retur: 'Batalkan Retur',
  title_cancel_transaction: 'Batalkan Transaksi',
  subtitle_cancel_retur: 'Anda yakin akan membatalkan proses retur barang?',
  subtitle_cancel_transaction: 'Anda yakin akan memproses pembatalan transaksi?',
  cancel_retur_text: 'Tidak',
  ok_retur_text: 'Ya, Batalkan',
  cancel_voucher_text: 'Batal',
  ok_voucher_text: 'Ya, gunakan',
  title_voucher_confirmation: 'Voucher',
  subtitle_voucher_confirmation: 'Menggunakan voucher akan mengahapus pembayaran selain voucher',
  title_change_transaction: 'Ubah Transaksi',
  title_split_item: 'Hapus Barang Utama?',
  ok_delete_split_text: 'Hapus',
  change_transaction: 'Apa anda yakin ingin ubah transaksi? pembayaran yang sudah dimasukkan akan terhapus',
  title_payment_rounding: 'Simpan Pembayaran',
  ok_rounding_text: 'Ya, simpan',
  change_payment_rounding:
    'Jika Anda menggunakan pembayaran ini, Detail Total Pembayaran akan berubah sebagai berikut:',
  title_delete_all_transaction: 'Hapus Semua Transaksi',
  title_delete_transaction: 'Hapus Transaksi',
  subtitle_delete_all_transaction: 'Anda yakin akan menghapus Semua Transaksi dari Transaksi Perangkat?',
  subtitle_delete_transaction: 'Anda yakin akan menghapus Transaksi ini dari Transaksi Perangkat?',
  ok_delete_text: 'Ya, Hapus',
  ok_continue_order_text: 'Ya, lanjutkan',
  title_validate_promotion: 'Promosi Tidak Berlaku',
  subtitle_validate_promotion: 'Lanjutkan tanpa promosi? Pembayaran akan dihapus.',
  subtitle_remove_master_item:
    'Jika kamu menghapus Barang Utama, maka Barang Pecahan otomatis akan menggantikan Barang Utama',
  subtitle_remove_confirmation:
    'Apakah Anda yakin ingin mengubah transaksi? Perubahan transaksi akan menghapus atau mengubah promosi saat ini',
  cancel_text_payment_promotion: 'Ubah Metode',
  complete_text_requirement_promotion: 'Lengkapi Syarat',
  title_offline_promotion: 'Tidak ada internet',
  subtitle_offline_promotion:
    'Promosi tidak dapat di proses karena tidak ada koneksi internet. Hapus promosi ini?',
  ok_offline_promotion: 'Cek Koneksi Internet',
  title_skip_free_item: 'Lewati Barang Gratis',
  skip_free_item: 'Apa anda yakin ingin melewati pilih barang gratis? promosi',
  title_close_qris_confirmation: 'Pembayaran belum selesai',
  subtitle_close_qris_confirmation:
    'Menutup popup ini bisa menghentikan proses pembayaran QRIS. Pastikan pelanggan belum melakukan pembayaran sebelum menutup.',
};

export default {
  alert,
  app,
  modal,
};
