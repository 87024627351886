import bopisRequest from 'services/http/bopis.request';
import { db } from 'services/indexdb/connection';
import { Maybe } from 'types';
import {
  IAssignItemList,
  IDetailTransaction,
  IGetResi,
  IItemCreatePickList,
  IItemToPickList,
  IRequestCreatePicklist,
  IResponseCreatePicklist,
  IResponsePickupLabel,
} from 'types/bopis.types';
import { Variant } from 'types/products.types';

type useBopisProps = {
  getItemToPick: (orderDetail: IDetailTransaction, isWms: boolean) => Promise<IItemToPickList[]>;
  processPickList: (
    orderDetail: IDetailTransaction,
    items: IItemCreatePickList[],
    isWms: boolean,
    picker_id: string
  ) => Promise<IResponseCreatePicklist>;
  assignItemToList: (
    barcode: string,
    itemList: IItemToPickList[],
    isWms: boolean
  ) => Promise<Maybe<IAssignItemList>>;
  getPickupLabel: (ids: Array<number>, isWms: boolean) => Promise<IResponsePickupLabel>;
  getAirwayBill: (id: number, isWms: boolean) => Promise<IGetResi>;
};

const useBopis = (): useBopisProps => {
  const getItemToPick = async (
    orderDetail: IDetailTransaction,
    isWms: boolean
  ): Promise<IItemToPickList[]> => {
    try {
      const data = { ids: [orderDetail.salesorder_id] };
      const itemList = await bopisRequest.getItemToPickupList(data, isWms);
      const itemToPickList = await getItemPicklist(itemList, orderDetail);
      return Promise.resolve(itemToPickList);
    } catch (error: any) {
      return Promise.reject(error);
    }
  };

  const getItemPicklist = async (
    itemList: IItemToPickList[],
    orderDetail: IDetailTransaction
  ): Promise<IItemToPickList[]> => {
    const newItemList: IItemToPickList[] = [];
    await Promise.all(
      itemList.map(async (item) => {
        const exists =
          Object.keys(orderDetail).length !== 0
            ? orderDetail.items.find((i) => i.item_id === item.item_id)
            : null;

        if (exists) {
          newItemList.push({
            ...item,
            qty_ordered: item.qty_ordered,
            qty_picked: 0,
            serials: [],
            thumbnail: exists.thumbnail,
            item_name: exists.item_name,
            item_code: exists.item_code,
            amount: Number(exists.amount),
          });
        } else {
          const variant = await getProductById(item.item_id);
          newItemList.push({
            ...item,
            qty_ordered: item.qty_ordered,
            qty_picked: 0,
            serials: [],
            thumbnail: item.thumbnail,
            item_name: variant?.item_name,
            item_code: item.item_code,
          });
        }
      })
    );
    return Promise.resolve(newItemList);
  };

  const getProductById = async (itemId: number): Promise<Variant | undefined> => {
    return db.products.toArray().then((res) => {
      for (const product of res) {
        const findVariant = product.variants.find((i) => i.item_id === itemId);
        if (findVariant) {
          return findVariant;
        }
      }
    });
  };

  const processPickList = async (
    orderDetail: IDetailTransaction,
    items: IItemCreatePickList[],
    isWms: boolean,
    picker_id: string
  ) => {
    try {
      const data: IRequestCreatePicklist = {
        picklist_id: orderDetail.picklist_id ? orderDetail.picklist_id : 0,
        picklist_no: orderDetail.picklist_no ? orderDetail.picklist_no : '[auto]',
        salesorderIds: [orderDetail.salesorder_id],
        items: items,
        is_completed: true,
      };
      if (isWms) {
        data.is_warehouse = true;
        data.picker_id = picker_id;
      }
      const res = await bopisRequest.createPicklist(data, isWms);
      return Promise.resolve(res);
    } catch (error: any) {
      return Promise.reject(error);
    }
  };

  const assignItemToList = async (barcode: string, itemList: IItemToPickList[], isWms: boolean) => {
    try {
      const res = await bopisRequest.scanBarcode(barcode, isWms);
      const exists = itemList.find(
        (i) => i.item_id === res.item_id && Number(i.qty_ordered) !== Number(i?.qty_picked ?? 0)
      );

      if (!exists) {
        return Promise.resolve({
          error_message: `Barang ${barcode} tidak ditemukan`,
        });
      }

      if (Number(exists.end_qty) < Number(exists.qty_ordered)) {
        return Promise.resolve({
          error_message: `Stok ${barcode} kurang dari jumlah yang dipesan`,
        });
      }

      const qtyPicked = Number(exists.qty_picked ?? 0);
      const qtyOrdered = Number(exists.qty_ordered ?? 0);

      if (qtyPicked === qtyOrdered) {
        return Promise.resolve({ error_message: `${barcode} sudah terpenuhi` });
      }

      if (res.serial_no) {
        const checkSn = exists.serials?.find((i) => i.serial_no === res.serial_no);
        if (checkSn) {
          return Promise.resolve({ error_message: `${barcode} sudah terpenuhi` });
        }

        exists.qty_picked = qtyPicked + 1;
        exists.serials = [
          ...(exists.serials || []),
          {
            serial_no: res.serial_no,
            pick_scanned_date: new Date(),
          },
        ];
      } else if (res.batch_no) {
        exists.qty_picked = qtyPicked + 1;
        exists.serials = [
          ...(exists.serials || []),
          {
            batch_no: res.batch_no,
            pick_scanned_date: new Date(),
          },
        ];
      } else {
        exists.qty_picked = qtyPicked + 1;
      }

      return Promise.resolve({ data: res, exists });
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const getPickupLabel = async (ids: Array<number>, isWms: boolean) => {
    try {
      const response = await bopisRequest.getPickupLabel(ids, isWms);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const getAirwayBill = async (id: number, isWms: boolean) => {
    try {
      const response = await bopisRequest.getShipmentOrder(id, isWms);
      return Promise.resolve(response[0]);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  return {
    getItemToPick,
    processPickList,
    assignItemToList,
    getPickupLabel,
    getAirwayBill,
  };
};

export default useBopis;
