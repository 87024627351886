import { AddIcon, MinusIcon } from '@chakra-ui/icons';
import { HStack, IconButton, Input, InputProps } from '@chakra-ui/react';
import clsx from 'clsx';
import React from 'react';

type InputNumberProps = InputProps & {
  decrement?: () => void;
  increment?: () => void;
  size?: string | 'sm' | 'md' | 'lg' | undefined;
  maxWidth?: InputProps['maxWidth'];
  disabled?: boolean;
};

const InputNumber: React.FC<InputNumberProps> = ({
  increment,
  decrement,
  size = 'md',
  maxWidth = '100%',
  disabled = false,
  ...inputProps
}: InputNumberProps) => {
  return (
    <HStack spacing={0} position='relative' maxW={maxWidth} w='full'>
      <IconButton
        aria-label='minus'
        icon={<MinusIcon />}
        variant='outline'
        borderRight={0}
        borderRightRadius={0}
        onFocus={(e: any) => e.target.blur()}
        onClick={() => decrement && decrement()}
        size={size}
        isDisabled={disabled}
      />
      <Input
        placeholder='Qty'
        rounded='0'
        size={size}
        {...inputProps}
        readOnly={disabled}
        className={clsx({
          '!focus:border-none  bg-gray-100 !outline-none focus:cursor-not-allowed': disabled,
        })}
      />
      <IconButton
        aria-label='plus'
        icon={<AddIcon />}
        variant='outline'
        borderLeft={0}
        borderLeftRadius={0}
        onFocus={(e: any) => e.target.blur()}
        onClick={() => increment && increment()}
        size={size}
        isDisabled={disabled}
      />
    </HStack>
  );
};

export default InputNumber;
