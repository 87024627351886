import { CheckCircleIcon } from '@chakra-ui/icons';
import { Box, Flex, Image as ChakraImage, Stack, Text, VStack } from '@chakra-ui/react';
import EmptyOrder from 'assets/svg/EmptyOrder.svg';
import { SubTitle, Title } from 'components/typography';
import React from 'react';
import { IQrisPaymentTransaction } from 'types/transaction.types';
import { convertToLocalDate, currencyFormat } from 'utils';

interface ICardDetailProps {
  detailOrder: IQrisPaymentTransaction;
}

export const DetailQrisPayment: React.FC<ICardDetailProps> = ({ detailOrder }: ICardDetailProps) => {
  return (
    <Flex
      bg='white'
      position='relative'
      flexDir='column'
      w='full'
      borderLeft='1px'
      borderColor='gray.200'
      roundedBottomRight='lg'
      h='full'
      overflowY='auto'
    >
      {Object.keys(detailOrder).length === 0 && (
        <Stack alignItems='center' h='full' justifyContent='center' spacing={8}>
          <ChakraImage src={EmptyOrder} display='inline-block' />
          <Box textAlign='center' w='65%'>
            <Title>Pilih Untuk Detail Transaksi</Title>
            <SubTitle fontSize='16px' mt={4}>
              Cari barang dan lihat harga dari setiap barang yang ada
            </SubTitle>
          </Box>
        </Stack>
      )}
      {Object.keys(detailOrder).length > 0 && (
        <>
          <React.Fragment>
            <Flex alignItems='center' justifyContent='space-between' p={4}>
              <Box>
                <Title fontSize='12px'>Detail Pembayaran</Title>
              </Box>
            </Flex>
            <Stack alignItems='center' spacing={4} my={10}>
              <CheckCircleIcon color='green.500' h='60px' w='60px' />
              <Box textAlign='center' w='65%'>
                <SubTitle fontSize={14}>Sudah Terbayar!</SubTitle>
              </Box>
              <Title fontSize='18px'>{currencyFormat(Number(detailOrder.amount ?? 0))}</Title>
            </Stack>
            <Stack w='full' spacing={5} px={4}>
              <Box border='1px' borderColor='system.outline' p={5} rounded='4px' bg='system.smoke'>
                <VStack w='full'>
                  <Flex justifyContent='space-between' w='full'>
                    <Stack spacing={0}>
                      <Text fontSize='14px'>Tanggal Bayar:&nbsp;</Text>
                    </Stack>
                    <Stack>
                      <Text fontSize='14px'>{convertToLocalDate(detailOrder.created_date)}</Text>
                    </Stack>
                  </Flex>
                  <Flex justifyContent='space-between' w='full'>
                    <Stack spacing={0}>
                      <Text fontSize='14px'>No. Transaksi:&nbsp;</Text>
                    </Stack>
                    <Stack>
                      <Text fontSize='14px'>{detailOrder.salesorder_no ?? '-'}</Text>
                    </Stack>
                  </Flex>
                  <Flex justifyContent='space-between' w='full'>
                    <Stack spacing={0}>
                      <Text fontSize='14px'>Kode RRN:&nbsp;</Text>
                    </Stack>
                    <Stack>
                      <Text fontSize='14px'>{detailOrder.no_ref ?? '-'}</Text>
                    </Stack>
                  </Flex>
                  <Flex justifyContent='space-between' w='full'>
                    <Stack spacing={0}>
                      <Text fontSize='14px'>Metode Pembayaran:&nbsp;</Text>
                    </Stack>
                    <Stack>
                      <Text fontSize='14px'>{detailOrder.notes ?? '-'}</Text>
                    </Stack>
                  </Flex>
                  <Flex justifyContent='space-between' w='full'>
                    <Stack spacing={0}>
                      <Text fontSize='14px'>Nominal:&nbsp;</Text>
                    </Stack>
                    <Stack>
                      <Text fontSize='14px'>{currencyFormat(Number(detailOrder.amount ?? 0))}</Text>
                    </Stack>
                  </Flex>
                </VStack>
              </Box>
            </Stack>
          </React.Fragment>
        </>
      )}
    </Flex>
  );
};

export default DetailQrisPayment;
